import React, { useState, useContext, useEffect, useRef } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import CommonApi from '../CommonApi';
import { goToProduct, goToCategory, formatPrice, isDev } from '../Helper';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import Breadcrumbs from '../Breadcrumbs';
import CategoriesWidget from '../CategoriesWidget';
import { Image } from 'primereact/image';
import { Ripple } from 'primereact/ripple';
import { Paginator } from 'primereact/paginator';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const ProductsList = (props) => {
  const commonCtx = useContext(CommonApi);
  const { t } = useTranslation();
  let quantities = [];

  let categoryID = new URLSearchParams(window.location.search).get('categoryID')
    ? new URLSearchParams(window.location.search).get('categoryID')
    : '';
  if (categoryID && categoryID !== 'favorites') {
    categoryID = window.atob(categoryID);
  }
  const searchterm = new URLSearchParams(window.location.search).get('search')
    ? new URLSearchParams(window.location.search).get('search')
    : '';

  const [loading, setLoading] = useState(true);
  const [categoryTranslation, setCategoryTranslation] = useState(null);
  const [parentCategoryTranslation, setParentCategoryTranslation] = useState(null);
  const [pageInputTooltip, setPageInputTooltip] = useState('Press  Enter  key to go to this page.');

  const [dataviewValue, setDataviewValue] = useState(commonCtx.products);
  const [layout, setLayout] = useState(
    Cookies.get('productList') ? Cookies.get('productList') : 'grid'
  );
  const [sortKey, setSortKey] = useState('Name');
  const [sortOrder, setSortOrder] = useState(null);
  const [sortField, setSortField] = useState(null);

  const sortOptions = [
    { label: t('PriceHightoLow'), value: '!price' },
    { label: t('PriceLowtoHigh'), value: 'price' },
    { label: t('PartcodeA-Z'), value: 'Name' },
    { label: t('PartcodeZ-A'), value: '!Name' },
  ];

  const onPageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    getProducts(event.page + 1);
  };

  const template1 = {
    layout: ' CurrentPageReport PrevPageLink PageLinks  NextPageLink   RowsPerPageDropdown',
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },

    PrevPageLink: (options) => {
      return (
        <>
          <button
            type="button"
            className={options.className}
            onClick={() => goToPrev(options)}
            disabled={options.disabled}
          >
            <span className="p-3">{t('Previous')}</span>
            <Ripple />
          </button>
        </>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={() => goToNext(options)}
          disabled={options.disabled}
        >
          <span className="p-3">{t('Next')}</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span className={className} style={{ userSelect: 'none' }}>
            ...
          </span>
        );
      }

      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      return (
        <>
          <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            {t('GoToPage')}
            <InputNumber
              size="2"
              className="ml-1"
              tooltip={pageInputTooltip}
              onKeyDown={(e) => onPageInputKeyDown(e, options)}
            />{' '}
            of {options.totalPages}
          </span>
        </>
      );
    },
  };

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const goToPrev = (e) => {
    getProducts(currentPage - 1);
    setCurrentPage(currentPage - 1);
    setFirst(first - rows);
  };

  const goToNext = (e) => {
    getProducts(currentPage + 1);
    setCurrentPage(currentPage + 1);
    setFirst(first + rows);
  };

  const eyeUnder = (
    <div className="eyeUnder">
      <i className="pi pi-eye"></i>
    </div>
  );

  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(event.currentTarget.value);

      if (page < 0 || page > options.totalPages) {
        setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
        return false;
      } else {
        const first = currentPage ? rows * (page - 1) : 0;
        setFirst(first);
        setPageInputTooltip('Enter number to go to this page.');
      }

      getProducts(event.currentTarget.value);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    }
  };

  useEffect(() => {
    if (categoryID === 'favorites') {
      document.title = commonCtx.title + ` - ${t('Favourites')}`;
    } else {
      document.title = commonCtx.title + ` - ${t('ProductsList')}`;
    }
    if (!commonCtx.loading) {
      if (!commonCtx.isLoggedIn) {
        location.assign('/' + Cookies.get('languageID') + '/');
      } else {
        if (loading) {
          getProducts(currentPage);
          if (categoryID) {
            setCategoryTranslation(commonCtx.getTranslationCategory(categoryID));
          }
          setLoading(false);
        }
      }
    }
  });

  const getProducts = (currentPage) => {
    const buyer = commonCtx.me.Buyer;

    // SEARCH BY TERM
    if (searchterm) {
      setCategoryTranslation(t('Search') + ': ' + searchterm);

      // SHOW SEARCH RESULTS
      // only debug
      // https://sandboxapi.ordercloud.io/v1/me/products?search=4PK&searchType=ExactPhrasePrefix&depth=all
      commonCtx
        .ListProducts({
          search: searchterm,
          searchOn: ['Name', 'xp.InternalPartNumber'], // , 'PriceSchedule?.xp.AlternativePartNumber'],
          searchType: 'ExactPhrasePrefix',
          page: currentPage,
          pageSize: 20,
          /* filters: {
            'InventoryRecords.QuantityAvailable': '>0',
            'InventoryRecords.AddressID': commonCtx.addressID,
          }, */
          addressId: commonCtx.addressId,
          sortBy: ['Name'],
        })
        .then((productList) => {
          // check if is present in PriceSchedule?.xp.AlternativePartNumber
          if (productList.length === 0) {
            commonCtx
              .GetProductsByAlternativePartNumber(searchterm, {
                addressId: commonCtx.addressId,
              })
              .then((productList) => {
                if (isDev) {
                  console.log('productList', productList);
                }
                setLoading(false);
                setDataviewValue(productList);
              })
              .catch(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
            setDataviewValue(productList);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (categoryID === 'favorites') {
      // FAVORITES
      if (commonCtx.me?.xp?.FavoriteProducts && commonCtx.me?.xp?.FavoriteProducts.length > 0) {
        let promises = [];
        for (let i = 0; i < commonCtx.me?.xp.FavoriteProducts.length; i++) {
          promises[i] = commonCtx.GetProduct(
            commonCtx.me?.xp.FavoriteProducts[i],
            commonCtx.addressId,
            'favorites'
          );
        }

        Promise.all(promises)
          .then((data) => {
            if (isDev) {
              console.log('favorites', data);
            }
            setDataviewValue(data);
            setLoading(false);
            setCategoryTranslation('Favorites');
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setDataviewValue(null);
        setLoading(false);
      }
    } else {
      // SEARCH BY CATEGORY
      // page=1&pageSize=100&sortBy=I
      commonCtx
        .ListProducts({
          categoryID: categoryID,
          page: currentPage,
          pageSize: rows,
          /* filters: {
            'InventoryRecords.QuantityAvailable': '>0',
            'InventoryRecords.AddressID': commonCtx.addressID,
          }, */
          sortBy: ['Name'],
          addressId: commonCtx.addressId,
        })
        .then((productList) => {
          setLoading(false);
          setDataviewValue(productList);
          if (productList[0]?.xp.CategoryID) {
            setParentCategoryTranslation(
              commonCtx.getTranslationCategory(productList[0]?.xp.CategoryID)
            );
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onSortChange = (event) => {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      setSortOrder(-1);
      setSortField(value.substring(1, value.length));
      setSortKey(value);
    } else {
      setSortOrder(1);
      setSortField(value);
      setSortKey(value);
    }
  };

  const qtyButtons = (dataProductId, minQty, maxQty, qtyMultiplier) => {
    quantities[dataProductId] = minQty ? minQty : 1;
    return (
      <span className="addQty mt-2">
        <span className="p-inputnumber p-component p-inputwrapper p-inputwrapper-filled p-inputnumber-buttons-horizontal">
          {!Cookies.get('isSalesAdmin') && (
            <InputNumber
              showButtons
              buttonLayout="horizontal"
              max={maxQty ? maxQty : 999999}
              min={minQty ? minQty : 1}
              step={qtyMultiplier ? qtyMultiplier : 1}
              inputClassName="w-3rem text-center"
              inputId={'qty' + dataProductId}
              value={minQty ? minQty : 1}
              onValueChange={(e) => {
                // check multiplier !
                if (e.value % qtyMultiplier === 0) {
                  quantities[dataProductId] = e.value;
                } else {
                  document.getElementById('qty' + dataProductId).value = quantities[dataProductId];
                  commonCtx.showError(e.value + ' ' + t('NotMultiple'));
                }
              }}
              decrementButtonClassName="p-button-text"
              incrementButtonClassName="p-button-text"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            ></InputNumber>
          )}
        </span>
      </span>
    );
  };

  const dataviewHeader = (
    <div className="grid grid-nogutter">
      <div className="col-6" style={{ textAlign: 'left' }}>
        <Dropdown
          value={sortKey}
          defaultValue="Code"
          options={sortOptions}
          optionLabel="label"
          placeholder="Sort By"
          onChange={onSortChange}
        />
      </div>
      <div className="col-6" style={{ textAlign: 'right' }}>
        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
      </div>
    </div>
  );

  const dataviewListItem = (data) => {
    return (
      <div className="col-12 ">
        <div className="flex flex-column md:flex-row align-items-center pl-2 pr-2 w-full">
          <div className="flex flex-column m-2 mr-4  p-1 eyeUnderContainer">
            <Image
              template={eyeUnder}
              preview
              width="120"
              src={`${data.thumbnail}`}
              onClick={(e) => {
                if (e.target.src) {
                  goToProduct(data.ID);
                }
              }}
              onError={(e) => (e.target.src = '/nophoto.jpg')}
              alt={data?.Name}
              className="imageThumb"
            />
          </div>
          <div className="flex-1 text-center md:text-left">
            <div
              className="mt-2 mb-2 font-bold text-2xl blueText hoverBlack"
              onClick={() => goToProduct(data.ID)}
            >
              {data?.Name}
            </div>
            <div className="mb-2 text-l descriptionProductList">
              {data?.xp?.InternalPartNumber &&
              data?.xp?.InternalPartNumber !== data?.Name &&
              data?.PriceSchedule?.xp?.AlternativePartNumber &&
              data?.PriceSchedule?.xp?.AlternativePartNumber !== data?.Name
                ? data?.xp?.InternalPartNumber +
                  ' - ' +
                  data?.PriceSchedule?.xp?.AlternativePartNumber
                : data?.xp?.InternalPartNumber && data?.xp?.InternalPartNumber !== data?.Name
                ? data?.xp?.InternalPartNumber
                : data?.PriceSchedule?.xp?.AlternativePartNumber &&
                  data?.PriceSchedule?.xp?.AlternativePartNumber !== data?.Name
                ? data?.PriceSchedule?.xp?.AlternativePartNumber
                : ' '}
            </div>
            <div className="flex align-items-center  cursor-pointer">
              <i className="pi pi-tag mr-2"></i>
              <span className="font-semibold" onClick={() => goToCategory(categoryID)}>
                {data?.xp.Category}
              </span>
            </div>

            {!Cookies.get('isSalesAdmin') && (
              <div
                className={classNames(
                  'flex flex-row justify-content-between  align-items-center mt-2 ',
                  {
                    ' hoverBlue': !commonCtx.isFavorite(data?.ID),
                    ' hoverBlack': commonCtx.isFavorite(data?.ID),
                  }
                )}
                hidden={Cookies.get('isSalesAdmin')}
                onClick={() => commonCtx.addToFavorites(data?.ID)}
              >
                <div>
                  <i
                    className={classNames('pi mr-2', {
                      'pi-heart  ': !commonCtx.isFavorite(data?.ID),
                      'pi-heart-fill text-orange-500  ': commonCtx.isFavorite(data?.ID),
                    })}
                  ></i>
                  {!commonCtx.isFavorite(data?.ID) && (
                    <span className="  ml-1  ">{t('Add to favorites')}</span>
                  )}
                  {commonCtx.isFavorite(data?.ID) && (
                    <span className=" text-orange-500 ml-1   ">{t('Remove from favorites')}</span>
                  )}
                </div>

                {commonCtx.showStock === 'Y' && (
                  <div
                    className={`text-center product-badge status-${data.statusStock} width150 right mt-1  mr-2`}
                  >
                    {t(data.labelStock)}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
            <span className="text-2xl font-semibold mb-2 align-self-center md:align-self-end">
              {formatPrice(data.price, commonCtx.locale)}
            </span>
            {qtyButtons(
              data?.ID,
              data?.PriceSchedule?.MinQuantity,
              data?.PriceSchedule?.MaxQuantity,
              data?.PriceSchedule?.xp.QuantityMultiplier
            )}

            {!Cookies.get('isSalesAdmin') && (
              <Button
                icon="pi pi-shopping-cart"
                label={t('Add to Cart')}
                hidden={Cookies.get('isSalesAdmin') ? true : false}
                onClick={() => commonCtx.addToCart(data, quantities[data?.ID])}
                className="mt-2"
              ></Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const dataviewGridItem = (data) => {
    return (
      <div className="col-12 md:col-6 lg:col-4 xl:col-3 pointer">
        <div className="flex flex-column card m-2 border-1 surface-border productCard">
          <div className="flex align-items-center justify-content-between">
            <div className="flex align-items-center pointer height32">
              <i className="pi pi-tag mr-2 " />
              <span className="font-semibold" onClick={() => goToCategory(categoryID)}>
                {data?.xp.Category.length >= 30
                  ? data?.xp.Category.substr(0, 30) + '\u2026'
                  : data?.xp.Category}
              </span>
            </div>

            {commonCtx.showStock === 'Y' && (
              <div
                className={`flex align-items-right  text-center product-badge status-${data.statusStock}`}
              >
                {t(data.labelStock)}
              </div>
            )}
          </div>
          <div className="text-center eyeUnderContainer">
            <Image
              preview
              template={eyeUnder}
              onClick={(e) => {
                if (e.target.src) {
                  goToProduct(data.ID);
                }
              }}
              width="120"
              src={`${data.thumbnail}`}
              alt={data.name}
              onError={(e) => (e.target.src = '/nophoto.jpg')}
              className="  my-3 mx-0 imageThumb"
            />
            <div
              className="text-2xl font-bold blueText hoverBlack mb-1"
              onClick={() => goToProduct(data.ID)}
            >
              {data?.Name}
            </div>
            <div className="mb-2 text-l descriptionProductList">
              {data?.xp?.InternalPartNumber &&
              data?.xp?.InternalPartNumber !== data?.Name &&
              data?.PriceSchedule?.xp?.AlternativePartNumber &&
              data?.PriceSchedule?.xp?.AlternativePartNumber !== data?.Name
                ? data?.xp?.InternalPartNumber +
                  ' - ' +
                  data?.PriceSchedule?.xp?.AlternativePartNumber
                : data?.xp?.InternalPartNumber && data?.xp?.InternalPartNumber !== data?.Name
                ? data?.xp?.InternalPartNumber
                : data?.PriceSchedule?.xp?.AlternativePartNumber &&
                  data?.PriceSchedule?.xp?.AlternativePartNumber !== data?.Name
                ? data?.PriceSchedule?.xp?.AlternativePartNumber
                : ' '}
            </div>
          </div>
          <div className="flex align-items-center justify-content-between">
            <span className="text-2xl font-semibold">
              {formatPrice(data.price, commonCtx.locale)}
            </span>
            {qtyButtons(
              data?.ID,
              data?.PriceSchedule?.MinQuantity,
              data?.PriceSchedule?.MaxQuantity,
              data?.PriceSchedule?.xp.QuantityMultiplier
            )}
            <div className="flex flex-row">
              {!Cookies.get('isSalesAdmin') && (
                <div
                  className=" hoverBlack mr-2 mt-2"
                  hidden={Cookies.get('isSalesAdmin')}
                  onClick={() => commonCtx.addToFavorites(data?.ID)}
                >
                  <i
                    className={classNames('pi  cursor-pointer ', {
                      'pi-heart hoverBlue ': !commonCtx.isFavorite(data?.ID),
                      'pi-heart-fill text-orange-500 hoverBlack': commonCtx.isFavorite(data?.ID),
                    })}
                  ></i>
                </div>
              )}

              {!Cookies.get('isSalesAdmin') && (
                <Button
                  icon="pi pi-shopping-cart"
                  onClick={() => commonCtx.addToCart(data, quantities[data?.ID])}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (data, layout) => {
    if (!data) {
      return;
    }

    if (layout === 'list') {
      Cookies.set('productList', 'list');
      return dataviewListItem(data);
    } else if (layout === 'grid') {
      Cookies.set('productList', 'grid');
      return dataviewGridItem(data);
    }
  };

  return (
    <>
      {commonCtx.qtyExceededDialog()}
      <Breadcrumbs
        leaf={categoryTranslation}
        category={parentCategoryTranslation}
        // categoryLink={() => goToCategory(commonCtx.parentCategoryID)}
        className="mb-0"
      ></Breadcrumbs>
      <div className="col-12 ">
        <div className="grid">
          <CategoriesWidget></CategoriesWidget>
          <div className="col-12 xl:col-10 card shadow-2   mt-2">
            <DataView
              // loading={loading}
              value={dataviewValue}
              layout={layout}
              rows={rows}
              sortOrder={sortOrder}
              sortField={sortField}
              itemTemplate={itemTemplate}
              header={dataviewHeader}
            ></DataView>
            {categoryID !== 'favorites' && commonCtx?.productListLength > 20 && (
              <Paginator
                template={template1}
                first={first}
                rows={rows}
                totalRecords={commonCtx?.productListLength}
                onPageChange={onPageChange}
              ></Paginator>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsList;
