import React, { useState, useEffect, useContext } from 'react';
import CommonApi from '../CommonApi';
import { goToFavorites, goToOrders } from '../Helper';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const MyOrdersSummary = (props) => {
  const userInfo = props.userInfo;
  const catalog = props.catalog;
  const favorites = props.favorites;
  const commonCtx = useContext(CommonApi);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!commonCtx.loading && loading) {
      if (!commonCtx.isLoggedIn) {
        location.assign('/' + Cookies.get('languageID') + '/');
      } else {
        commonCtx.listOrdersExcel();
        commonCtx.listAddresses();
        setLoading(false);
      }
    }
  });

  return (
    <div className="col-12 ">
      <div className="surface-section p-4 shadow-2">
        <div className="flex align-items-start flex-column lg:flex-row lg:justify-content-between">
          <div>
            <span className="text-900 font-medium text-3xl">
              {t('Welcome')} {userInfo?.FirstName} {userInfo?.LastName},
            </span>
            <div className="flex align-items-center flex-wrap text-lg">
              <div className="mr-5 mt-3">
                <span className="font-medium text-500">{t('Company')}</span>
                <div className="text-700 mt-2">{catalog?.Name}</div>
              </div>
              <div className="mr-5 mt-3">
                <span className="font-medium text-500">{t('CustomerCode')}</span>
                <div className="text-700 mt-2">{commonCtx.customerCode}</div>
              </div>
              <div className="mr-5 mt-3">
                <span className="font-medium text-500">{t('Email')}</span>
                <div className="text-700 mt-2">{userInfo?.Email}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid mt-2">
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <h5>{t('Orders')}</h5>
                <div className="text-900 font-medium text-xl">{commonCtx.ordersexcel.length}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: '2.5rem', height: '2.5rem' }}
              >
                <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
              </div>
            </div>
            <span onClick={() => goToOrders()} className="text-blue-500 hoverBlack">
              {t('View')} {t('All')}
            </span>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <h5>
                  {t('Orders')} {t('Opened')}
                </h5>
                <div className="text-900 font-medium text-xl">
                  {commonCtx.ordersexcel.length -
                    commonCtx.ordersexcel.filter((order) => order.Status === 'Completed').length -
                    commonCtx.ordersexcel.filter((order) => order.Status === 'Canceled').length}
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-orange-100 border-round"
                style={{ width: '2.5rem', height: '2.5rem' }}
              >
                <i className="pi pi-folder-open text-orange-500 text-xl"></i>
              </div>
            </div>
            <div>
              <span onClick={() => goToOrders('Open')} className="text-blue-500 hoverBlack mr-2">
                {t('View')} {t('Opened')}
              </span>
              |
              <span
                onClick={() => goToOrders('AwaitingApproval')}
                className="text-blue-500 hoverBlack"
              >
                {' '}
                {t('Pending')}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <h5>
                  {t('Orders')} {t('Closed')}
                </h5>
                <div className="text-900 font-medium text-xl">
                  {commonCtx.ordersexcel.filter((order) => order.Status === 'Completed').length}
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-green-100 border-round"
                style={{ width: '2.5rem', height: '2.5rem' }}
              >
                <i
                  className="pi pi-verified
 text-green-500 text-xl"
                ></i>
              </div>
            </div>
            <span onClick={() => goToOrders('Completed')} className="text-blue-500 hoverBlack mr-2">
              {t('View')} {t('Closed')}
            </span>
            {t('|')}
            <span onClick={() => goToOrders('Canceled')} className="text-blue-500 hoverBlack ml-2">
              {t('ViewCanceled')}
            </span>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <h5>{t('Favorites')}</h5>
                <div className="text-900 font-medium text-xl">{favorites?.length}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-purple-100 border-round"
                style={{ width: '2.5rem', height: '2.5rem' }}
              >
                <i
                  className="pi pi-heart
 text-purple-500 text-xl"
                ></i>
              </div>
            </div>
            <span
              hidden={Cookies.get('isSalesAdmin')}
              onClick={() => goToFavorites()}
              className="text-blue-500 hoverBlack"
            >
              {t('View')} {t('All')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyOrdersSummary;
