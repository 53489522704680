import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from './../../assets/logo.png';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';

const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <div
      id="footer-outer"
      data-cols="3"
      data-custom-color="true"
      data-disable-copyright="false"
      data-matching-section-color="false"
      data-copyright-line="true"
      data-using-bg-img="true"
      data-bg-img-overlay="0.0"
      data-full-width="1"
      data-using-widget-area="true"
      data-link-hover="underline"
      className="loaded"
    >
      <div className="row flex flex-row footer">
        <div className="col-4 container  span_3 ml-2">
          <div className="widget_text widget widget ">
            <div className="flex flex-column linksFooter   mt-3">
              <a
                href="https://www.daycoaftermarket.com/en/gcs/"
                target="_blank"
                rel="noreferrer"
                data-wpel-link="internal"
              >
                {t('GeneralConditionsOfSale')}
              </a>
              <a
                href="https://www.daycoaftermarket.com/"
                target="_blank"
                rel="noreferrer"
                data-wpel-link="internal"
              >
                {t('AftermarketWebsite')}
              </a>
              <a
                href="https://www.dayco.com/en/"
                target="_blank"
                rel="noreferrer"
                data-wpel-link="external"
              >
                {t('CorporateWebsite')}
              </a>
              <a
                href="https://www.dayco.com/en/locations/"
                target="_blank"
                rel="noreferrer"
                data-wpel-link="external"
              >
                {t('Locations')}
              </a>
            </div>
          </div>
        </div>
        <div className="col-4 footer_logo mt-2">
          © {new Date().getFullYear()} - <RichText tag="span" field={props.fields?.Text} />
          <div className="footer_logo mt-4">
            <Image
              field={
                props?.fields?.Logo && props?.fields?.Logo?.value?.src.indexOf('dayco//') === -1
                  ? props?.fields?.Logo
                  : { src: logo }
              }
              alt={props?.fields?.Logo?.value?.alt}
            />
          </div>
        </div>

        <div className="col-4  container mt-5 ">
          <div id="custom_html-3" className="widget_text widget widget_custom_html mr-3">
            <div className="textwidget custom-html-widget">
              <div id="">
                <div id="dayco-social" className="widget zoom-social-icons-widget">
                  <p className="footerText">{t('FollowUs')}</p>
                  <a
                    className="zoom-social_icons-list__link mr-2"
                    href="https://www.facebook.com/DaycoOfficial/"
                    target="_blank"
                    rel="noreferrer"
                    data-wpel-link="external"
                  >
                    <i className="pi pi-facebook"></i>
                  </a>
                  <a
                    className="zoom-social_icons-list__link mr-2"
                    href="https://twitter.com/DaycoOfficial"
                    target="_blank"
                    rel="noreferrer"
                    data-wpel-link="external"
                  >
                    <i className="pi pi-twitter"></i>
                  </a>
                  <a
                    className="zoom-social_icons-list__link mr-2"
                    href="https://www.linkedin.com/company/daycoofficial/"
                    target="_blank"
                    rel="noreferrer"
                    data-wpel-link="external"
                  >
                    <i className="pi pi-linkedin"></i>
                  </a>
                  <a
                    className="zoom-social_icons-list__link mr-2"
                    href="https://www.youtube.com/DaycoOfficial"
                    target="_blank"
                    rel="noreferrer"
                    data-wpel-link="external"
                  >
                    <i className="pi pi-youtube"></i>
                  </a>
                  <a
                    className="zoom-social_icons-list__link"
                    href="https://www.instagram.com/daycoofficial/"
                    target="_blank"
                    rel="noreferrer"
                    data-wpel-link="external"
                  >
                    <i className="pi pi-instagram"></i>
                  </a>
                  <a
                    className="zoom-social_icons-list__link"
                    href="https://www.tiktok.com/@daycoofficial/"
                    target="_blank"
                    rel="noreferrer"
                    data-wpel-link="external"
                  >
                    <i className="pi pi-tiktok"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
