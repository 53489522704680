import React, { useState, useContext, useEffect } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import CommonApi from '../CommonApi';
import YoutubeEmbed from '../YoutubeEmbed';
import Breadcrumbs from '../Breadcrumbs';
import CategoriesWidget from '../CategoriesWidget';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { formatPrice, goToCategory } from '../Helper';

export const emptyProduct = {
  PriceSchedule: {
    OwnerID: '',
    ID: '',
    Name: '',
    ApplyTax: false,
    ApplyShipping: false,
    MinQuantity: 1,
    MaxQuantity: 10,
    UseCumulativeQuantity: false,
    RestrictedQuantity: false,
    PriceBreaks: [
      {
        Quantity: 1,
        Price: 10,
        SalePrice: null,
      },
    ],
    Currency: null,
    SaleStart: null,
    SaleEnd: null,
    IsOnSale: false,
    xp: {},
  },
  ID: '',
  Name: '',
  Description: '',
  QuantityMultiplier: 1,
  ShipWeight: 10,
  ShipHeight: null,
  ShipWidth: null,
  ShipLength: 100,
  Active: true,
  SpecCount: 0,
  VariantCount: 0,
  ShipFromAddressID: '',
  Inventory: {
    Enabled: true,
    NotificationPoint: null,
    VariantLevelTracking: false,
    OrderCanExceed: true,
    QuantityAvailable: 0,
    LastUpdated: '',
  },
  DefaultSupplierID: null,
  AllSuppliersCanSell: false,
  Returnable: false,
  xp: {
    FreeShipping: false,
    Facets: {},
    UnitOfMeasure: {
      Qty: 1,
      Unit: '',
    },
    Images: [
      {
        ThumbnailUrl: '',
        Url: '',
        Tags: null,
      },
    ],
    ProductType: '',
    Tax: {
      Description: '',
      LongDescription: null,
      Code: '',
    },
    FreeShippingMessage: '',
    BundledProducts: null,
    SizeTier: 'A',
    Note: '',
    Currency: null,
    Documents: null,
    RelatedProductIDs: null,
  },
};

const ProductPage = () => {
  const [dataProduct, setDataProduct] = useState(emptyProduct);
  const [loading, setLoading] = useState(true);
  const [categoryTranslation, setCategoryTranslation] = useState(null);
  const [subcategoryTranslation, setSubCategoryTranslation] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [subCategoryID, setSubCategoryID] = useState(null);

  const productID = new URLSearchParams(window.location.search).get('productID');
  const commonCtx = useContext(CommonApi);

  useEffect(() => {
    document.title = commonCtx.title + ' - ' + dataProduct.Name;
    if (!commonCtx.loading) {
      if (!commonCtx.isLoggedIn) {
        location.assign('/' + Cookies.get('languageID') + '/');
      } else {
        if (loading) {
          commonCtx
            .GetProduct(productID, commonCtx.addressId)
            .then((product) => {
              setDataProduct(product);
              setCategoryTranslation(commonCtx.getTranslationCategory(product.xp.CategoryID));
              setSubCategoryTranslation(commonCtx.getTranslationCategory(product.xp.SubCategoryID));
              setCategoryID(product.xp.CategoryID);
              setSubCategoryID(product.xp.SubCategoryID);
              setLoading(false);
              setQuantity(
                product.PriceSchedule?.MinQuantity ? product.PriceSchedule?.MinQuantity : 1
              );
            })
            .catch(() => {
              setLoading(false);
            });
        }
      }
    }
  });

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { t } = useTranslation();

  return (
    <>
      {commonCtx.qtyExceededDialog()}
      <Breadcrumbs
        leaf={dataProduct?.Name}
        category={categoryTranslation}
        // categoryLink={() => goToCategory(categoryID)}
        subcategory={subcategoryTranslation}
        subCategoryLink={() => goToCategory(subCategoryID)}
      ></Breadcrumbs>
      <div className="col-12 ">
        <div className="grid">
          <CategoriesWidget></CategoriesWidget>
          <div className="col-12 xl:col-10 card shadow-2    mt-2">
            {dataProduct !== emptyProduct && (
              <div className="grid">
                <div className="col-1 xl:col-1">
                  <div className="flex flex-column ">
                    {dataProduct?.xp?.Images.map((image, i) => {
                      return (
                        <img
                          src={`${image?.ThumbnailUrl}`}
                          key={i}
                          onError={(e) => (e.target.src = '/nophoto.jpg')}
                          className={classNames(
                            'w-full cursor-pointer border-2 border-round border-transparent transition-colors transition-duration-150 mb-2',
                            {
                              'border-primary': selectedImageIndex === i,
                            }
                          )}
                          onClick={() => setSelectedImageIndex(i)}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="col-5 xl:col-3">
                  <Image
                    preview
                    className=" imageThumbProduct"
                    width="99%"
                    onError={(e) => (e.target.src = '/nophoto.jpg')}
                    src={`${dataProduct?.xp?.Images[selectedImageIndex]?.Url}`}
                  />
                </div>
                <div className="col-6 xl:col-4">
                  <div className="ml-5">
                    <div className="flex align-items-center text-3xl font-bold text-900 mb-2 blueText">
                      {dataProduct?.Name}
                    </div>
                    <div className="  font-bold  flex align-items-center text-xl text-900">
                      {dataProduct?.Translation}
                    </div>
                    <div className="  mb-4">
                      <i className="pi pi-tag mr-2"></i>
                      {dataProduct?.xp.Category}
                    </div>
                    <div className="flex align-items-center text-xl font-medium text-900 mb-4">
                      <span className="text-1000 font-medium text-3xl block">
                        {formatPrice(dataProduct?.price, commonCtx.locale)}
                      </span>
                    </div>
                    <div className="mb-3  align-items-center justify-content-between  ">
                      <div className="labelMargin text-xl ">
                        {t('DaycoPartNumber')}:
                        <span className="font-bold  text-900 ">{' ' + dataProduct?.Name}</span>
                      </div>
                      {dataProduct?.xp?.InternalPartNumber !== dataProduct?.Name && (
                        <div className="labelMargin">
                          {t('InternalPartNumber')}
                          <span className="font-bold text-900 ">
                            {' ' + dataProduct?.xp?.InternalPartNumber}
                          </span>
                        </div>
                      )}
                      {dataProduct?.PriceSchedule?.xp?.AlternativePartNumber &&
                        dataProduct?.PriceSchedule?.xp?.AlternativePartNumber !==
                          dataProduct?.Name && (
                          <div className="labelMargin  ">
                            {t('CustomerPartNumber')}
                            <span className="font-bold text-900 ">
                              {' ' + dataProduct?.PriceSchedule?.xp?.AlternativePartNumber}
                            </span>
                          </div>
                        )}
                    </div>

                    <div className="flex flex-row mt-4 mb-4">
                      {!Cookies.get('isSalesAdmin') && (
                        <InputNumber
                          showButtons
                          buttonLayout="horizontal"
                          max={
                            dataProduct.PriceSchedule?.MaxQuantity
                              ? dataProduct.PriceSchedule?.MaxQuantity
                              : 9999
                          }
                          min={
                            dataProduct?.PriceSchedule?.MinQuantity
                              ? dataProduct?.PriceSchedule?.MinQuantity
                              : 1
                          }
                          inputId={'qty' + dataProduct.ID}
                          inputClassName="w-3rem text-center"
                          value={quantity}
                          step={
                            dataProduct?.PriceSchedule?.xp.QuantityMultiplier
                              ? dataProduct?.PriceSchedule?.xp.QuantityMultiplier
                              : 1
                          }
                          onValueChange={(e) => {
                            // check multiplier !
                            if (e.value % dataProduct?.PriceSchedule?.xp.QuantityMultiplier === 0) {
                              setQuantity(e.value);
                            } else {
                              document.getElementById('qty' + dataProduct.ID).value = quantity;
                              commonCtx.showError(e.value + ' ' + t('NotMultiple'));
                            }
                          }}
                          decrementButtonClassName="p-button-text"
                          incrementButtonClassName="p-button-text"
                          incrementButtonIcon="pi pi-plus"
                          decrementButtonIcon="pi pi-minus"
                        ></InputNumber>
                      )}
                    </div>
                    <div className="flex flex-row mt-4 mb-4">
                      {!Cookies.get('isSalesAdmin') && (
                        <Button
                          className="ml-0 width150"
                          icon="pi pi-shopping-cart "
                          label={t('Add to Cart')}
                          onClick={() => commonCtx.addToCart(dataProduct, quantity)}
                        />
                      )}
                    </div>

                    {commonCtx.showStock === 'Y' && (
                      <div
                        className={`mt-2 width150 text-center product-badge status-${dataProduct.statusStock}`}
                      >
                        {t(dataProduct.labelStock)}
                      </div>
                    )}
                    <div className="mb-3  align-items-center justify-content-between  ">
                      <div
                        className={classNames('flex align-items-center mt-3', {
                          ' hoverBlue': !commonCtx.isFavorite(dataProduct?.ID),
                          ' hoverBlack': commonCtx.isFavorite(dataProduct?.ID),
                        })}
                        hidden={Cookies.get('isSalesAdmin')}
                        onClick={() => commonCtx.addToFavorites(dataProduct?.ID)}
                      >
                        <i
                          className={classNames('pi  mr-2', {
                            'pi-heart  ': !commonCtx.isFavorite(dataProduct?.ID),
                            'pi-heart-fill text-orange-500 ': commonCtx.isFavorite(dataProduct?.ID),
                          })}
                        ></i>
                        {!commonCtx.isFavorite(dataProduct?.ID) && (
                          <span className="  ml-1 ">{t('Add to favorites')}</span>
                        )}
                        {commonCtx.isFavorite(dataProduct?.ID) && (
                          <span className=" text-orange-500 ml-1">
                            {t('Remove from favorites')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 xl:col-4">
                  <TabView>
                    {dataProduct?.xp?.CatalogLink?.length > 0 && (
                      <TabPanel header={t('Details')}>
                        <div className="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
                          {dataProduct?.Translation}
                          {dataProduct?.xp?.CatalogLink?.length > 0 && (
                            <div className="mt-2">
                              <a
                                href={dataProduct?.xp.CatalogLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="pi pi-external-link mr-2"></i>
                                {t('LinkToCatalog')}
                              </a>
                            </div>
                          )}
                          {dataProduct?.xp?.ReferenceNumberType && (
                            <div className="mt-2">
                              {dataProduct?.xp.ReferenceNumberType}:{' '}
                              <b>{dataProduct?.xp.ReferenceNumber}</b>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                    )}
                    {dataProduct?.xp?.KitParts?.length > 0 && (
                      <TabPanel header={t('KitParts')}>
                        <div className="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
                          {dataProduct?.xp?.KitParts?.map((kit) => (
                            <div key={kit.ChildInternalPartNumber} className="mb-4">
                              <div className="flex flex-column flex-wrap">
                                <div>
                                  {t('ChildInternalPartNumber')} :
                                  <b>{kit.ChildInternalPartNumber}</b>
                                </div>
                                <div>
                                  {t('Quantity')} :<b>{kit.Quantity}</b>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    )}
                    {dataProduct?.xp?.Cross?.length > 0 && (
                      <TabPanel header={t('OEApplications')}>
                        <div className="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
                          {dataProduct?.xp?.Cross?.map((brand) => (
                            <div key={brand.Make} className="mb-2">
                              <h5>{brand.Make}</h5>
                              <div className="flex align-items-center flex-wrap">
                                {brand.OES.map((oe) => (
                                  <div key={oe}>
                                    <Chip label={oe} className="mr-2 mb-2" />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    )}
                    {dataProduct?.xp?.Documents?.length > 0 && (
                      <TabPanel header={t('Documents')}>
                        <div className="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
                          {dataProduct?.xp?.Documents?.map((document) => (
                            <div key={document?.Url} className="mb-2">
                              <h5>{document.Type}</h5>
                              <div className="flex align-items-center flex-wrap">
                                <a href={document?.Url} target="_blank" rel="noreferrer">
                                  <i className="pi pi-external-link mr-2"></i>
                                  {document?.FileName}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    )}
                    {dataProduct?.xp?.Videos?.length > 0 && (
                      <TabPanel header={t('Video')}>
                        <YoutubeEmbed embedId={dataProduct?.xp?.Videos[0]?.Url} />
                      </TabPanel>
                    )}
                  </TabView>
                </div>
              </div>
            )}
            {!loading && dataProduct === emptyProduct && (
              <div className="grid">
                <div className="col-1 xl:col-1">{t('NotFound')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
