export const AppConfig = {
  scope: [
    'MeAddressAdmin',
    'AddressAdmin',
    'MeAdmin',
    'MeCreditCardAdmin',
    'MeXpAdmin',
    'UserGroupAdmin',
    'ApprovalRuleAdmin',
    'Shopper',
    'BuyerAdmin',
    'BuyerUserAdmin',
    'BuyerImpersonation',
    'BuyerReader',
    'PasswordReset',
    'SupplierReader',
    'ProductReader',
    'SupplierAddressReader',
    'OrderAdmin',
    'OrderReader',
    'AddressReader',
    'BuyerUserAdmin',
    'CatalogReader',
    'DaycoBuyerAdmin',
    'HSBaseBuyer',
    'HSLocationNeedsApproval',
    'HSOrderAdmin',
    'MeAddressAdmin',
    'MeAdmin',
    'MeCreditCardAdmin',
    'MeXpAdmin',
    'OrderAdmin',
    'PasswordReset',
    'ProductFacetReader',
    'ShipmentReader',
    'Shopper',
    'SupplierAddressReader',
    'SupplierReader',
    'UnsubmittedOrderReader',
    'UserGroupAdmin',
    'SecurityProfileAdmin',
    'SetSecurityProfile',
  ],
  shopperScope: [
    'Shopper',

    // 'AdminAddressReader' is just for reading admin addresses as a seller user on product create/edti
    // Will need to be updated to 'AdminAddressAdmin' when seller address create is implemented
    'ApiClientAdmin',
    'ApiClientReader',
    'AdminAddressAdmin',
    'AdminAddressReader',
    'MeAddressAdmin',
    'AddressReader',
    'MeAdmin',
    'BuyerUserAdmin',
    'UserGroupAdmin',
    'MeCreditCardAdmin',
    'MeXpAdmin',
    'Shopper',
    'CategoryReader',
    'ProductAdmin',

    // adding this for product editing and creation on the front end
    // this logic may be moved to the backend in the future and this might not be required
    'PriceScheduleAdmin',
    'AddressReader',
    'BuyerUserAdmin',
    'CatalogReader',
    'DaycoBuyerAdmin',
    'HSBaseBuyer',
    'HSLocationNeedsApproval',
    'HSOrderAdmin',
    'MeAddressAdmin',
    'MeAdmin',
    'MeCreditCardAdmin',
    'MeXpAdmin',
    'OrderAdmin',
    'PasswordReset',
    'ProductFacetReader',
    'ShipmentReader',
    'Shopper',
    'SupplierAddressReader',
    'SupplierReader',
    'UnsubmittedOrderReader',
    'UserGroupAdmin',
    'SupplierReader',
    'SupplierAddressReader',
    'BuyerAdmin',
    'OverrideUnitPrice',
    'OrderAdmin',
    'OrderReader',
    'OverrideTax',
    'OverrideShipping',
    'BuyerImpersonation',
    'AddressAdmin',
    'CategoryAdmin',
    'CatalogAdmin',
    'PromotionAdmin',
    'ApprovalRuleAdmin',
    'CreditCardAdmin',
    'SupplierAdmin',
    'SupplierUserAdmin',
    'SupplierUserGroupAdmin',
    'SupplierAddressAdmin',
    'AdminUserAdmin',
    'AdminUserGroupAdmin',
    'ProductFacetAdmin',
    'ProductFacetReader',
    'ShipmentAdmin',
    'UnsubmittedOrderReader',

    // custom roles used to conditionally display ui
    'ProductReader',
    'HSMeProductAdmin',
    'HSMeProductReader',
    'HSProductAdmin',
    'HSProductReader',
    'HSPromotionAdmin',
    'HSPromotionReader',
    'HSCategoryAdmin',
    'HSCategoryReader',
    'HSOrderAdmin',
    'HSOrderReader',
    'HSShipmentAdmin',
    'HSBuyerAdmin',
    'HSBuyerReader',
    'HSSellerAdmin',
    'HSReportReader',
    'HSReportAdmin',
    'HSSupplierAdmin',
    'HSMeSupplierAdmin',
    'HSMeSupplierAddressAdmin',
    'HSMeSupplierUserAdmin',
    'HSSupplierUserGroupAdmin',
    'HSStorefrontAdmin',
    'HSOrderReturnApprover',
    'MeAddressAdmin',
    'AddressAdmin', // Only for location owners
    'MeAdmin',
    'MeCreditCardAdmin',
    'MeXpAdmin',
    'UserGroupAdmin',
    'ApprovalRuleAdmin',
    'BuyerUserAdmin',
    'Shopper',
    'BuyerReader',
    'PasswordReset',
    'SupplierReader',
    'SupplierAddressReader',
    'HSLocationOrderApprover',
    'HSLocationViewAllOrders',

    'SecurityProfileAdmin',
    'SetSecurityProfile',
  ],
};
