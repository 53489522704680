import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Breadcrumbs from '../Breadcrumbs';
import CommonApi from '../CommonApi';
import { InputNumber } from 'primereact/inputnumber';
import { formatPrice, goToCheckout, goToProduct } from '../Helper';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { ScrollTop } from 'primereact/scrolltop';
import Cookies from 'js-cookie';

const Cart = (props) => {
  const [fromCheckout, setFromCheckout] = useState(props.fromCheckout ? true : false);
  const commonCtx = useContext(CommonApi);
  const [deleteID, setDeleteID] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { t } = useTranslation();
  const hidden = props.fromCheckout ? 'hidden' : '';

  useEffect(() => {
    document.title = commonCtx.title + ' - Cart';
  }, []);

  const imageBodyTemplate = (rowData) => {
    rowData = rowData.Product;
    return (
      <Image
        preview
        width="80"
        src={`${rowData?.xp.Images[0]?.ThumbnailUrl}`}
        onError={(e) => (e.target.src = '/nophoto.jpg')}
        alt={`${rowData?.xp.Images[0]?.ThumbnailUrl}`}
        className="imageThumbInCart"
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div className={`  product-badge status-${rowData.Product.statusStock} text-center `}>
        {t(rowData.Product.labelStock)}
      </div>
    );
  };

  const qtyTemplate = (rowdata) => {
    return (
      <span className="addQty">
        <span className="p-inputnumber p-component p-inputwrapper p-inputwrapper-filled p-inputnumber-buttons-horizontal">
          {!Cookies.get('isSalesAdmin') && (
            <InputNumber
              showButtons
              buttonLayout="horizontal"
              inputClassName="w-3rem text-center"
              value={rowdata.Quantity}
              inputId={'qty' + rowdata.ID}
              onValueChange={(e) => {
                console.log(e, rowdata?.PriceSchedule);
                if (e.value <= rowdata?.PriceSchedule?.MinQuantity) {
                  document.getElementById('qty' + rowdata.ID).value =
                    rowdata?.PriceSchedule?.MinQuantity;
                  return;
                }
                if (e.value >= rowdata?.PriceSchedule?.MaxQuantity) {
                  document.getElementById('qty' + rowdata.ID).value =
                    rowdata?.PriceSchedule?.MaxQuantity;
                  return;
                }

                if (e.value === 0) {
                  setDeleteDialog(true);
                  setDeleteID(rowdata.ID);
                } else {
                  // check multiplier !
                  console.log(rowdata);
                  if (e.value % rowdata?.PriceSchedule?.xp?.QuantityMultiplier === 0) {
                    commonCtx.modifyLineItem(rowdata, e.value);
                    rowdata.Quantity = e.value;
                  } else {
                    document.getElementById('qty' + rowdata.ID).value = rowdata.Quantity;
                    commonCtx.showError(e.value + ' ' + t('NotMultiple'));
                  }
                }
              }}
              max={
                rowdata?.PriceSchedule?.MaxQuantity ? rowdata?.PriceSchedule?.MaxQuantity : 999999
              }
              min={rowdata?.PriceSchedule?.MinQuantity ? rowdata?.PriceSchedule?.MinQuantity : 1}
              step={
                rowdata?.PriceSchedule?.xp.QuantityMultiplier
                  ? rowdata?.PriceSchedule?.xp.QuantityMultiplier
                  : 1
              }
              decrementButtonClassName="p-button-text"
              incrementButtonClassName="p-button-text"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            ></InputNumber>
          )}
        </span>
      </span>
    );
  };

  const clickTemplate = (rowData) => {
    return (
      <div
        className="text-xl font-bold pointer blueText  hoverBlack"
        onClick={() => goToProduct(rowData.Product.ID)}
      >
        {rowData.Product.Name}
      </div>
    );
  };

  const priceTemplateUnitPrice = (rowData) => {
    return formatPrice(rowData.UnitPrice, commonCtx.locale);
  };

  const priceTemplateUnitPriceCalculated = (rowData) => {
    return formatPrice(rowData.UnitPriceCalculated, commonCtx.locale);
  };

  const priceTemplateLineTotal = (rowData) => {
    return formatPrice(rowData.LineTotal, commonCtx.locale);
  };

  const priceTemplateLineTotalCalculated = (rowData) => {
    return formatPrice(rowData.LineTotalCalculated, commonCtx.locale);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-error"
          onClick={() => {
            setDeleteDialog(true);
            setDeleteID(rowData.ID);
          }}
        />
      </React.Fragment>
    );
  };

  const deleteDialogFooter = (
    <React.Fragment>
      <Button
        label={t('No')}
        icon="pi pi-times"
        className="p-button-text bg-red-500 textWhite"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label={t('Yes')}
        icon="pi pi-check"
        className="p-button-text bg-green-500 textWhite"
        onClick={() => {
          commonCtx.removeFromCart(deleteID);
          setDeleteDialog(false);
        }}
      />
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
        visible={deleteDialog}
        style={{ width: '450px' }}
        header={t('Confirm')}
        modal
        footer={() => deleteDialogFooter}
        onHide={() => setDeleteDialog(false)}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {deleteID && <span>{t('AreYouSureYouWantToDelete')} ?</span>}
        </div>
      </Dialog>
      {commonCtx.qtyExceededDialog()}
      <Breadcrumbs leaf={t('Cart')} hidden={fromCheckout}></Breadcrumbs>
      <div className="layout-content grid">
        <div className="col-12 md:col-10">
          <div className="card card-w-title global-sales ui-fluid">
            <DataTable
              loading={commonCtx.loadingCart}
              value={commonCtx.lineItems}
              responsiveLayout="scroll"
              stripedRows
            >
              <Column
                field="Image"
                header={t('Image')}
                hidden={hidden}
                style={{ width: '20%' }}
                body={imageBodyTemplate}
              ></Column>
              <Column
                style={{ width: '20%' }}
                field="Product.Name"
                hidden={hidden}
                header={t('PartNumber')}
                className="text-bold"
                body={clickTemplate}
              ></Column>
              <Column
                style={{ width: '20%' }}
                field="Product.Name"
                hidden={!hidden}
                header={t('PartNumber')}
                className="text-bold"
              ></Column>
              <Column
                field="Product.xp.SubCategory"
                style={{ width: '20%' }}
                header={t('Category')}
              ></Column>
              <Column
                field="Product.Translation"
                hidden={true}
                style={{ width: '20%' }}
                header={t('Name')}
              ></Column>
              <Column
                field="Status"
                style={{ width: '10%' }}
                header={t('Status')}
                hidden={commonCtx.showStock !== 'Y'}
                body={statusBodyTemplate}
              ></Column>
              <Column
                field="Quantity"
                hidden={!hidden}
                style={{ width: '10%' }}
                header={t('Quantity')}
              ></Column>
              <Column
                field="Quantity"
                style={{ width: '10%' }}
                hidden={hidden}
                header={t('Quantity')}
                body={qtyTemplate}
              ></Column>
              <Column
                field="UnitPrice"
                style={{ width: '10%' }}
                body={priceTemplateUnitPrice}
                header={t('UnitPrice')}
              ></Column>
              <Column
                field="LineTotal"
                style={{ width: '10%' }}
                body={priceTemplateLineTotal}
                header={t('Subtotal')}
              ></Column>
              <Column
                field="UnitPriceCalculated"
                style={{ width: '10%' }}
                className="text-bold"
                hidden={!hidden}
                body={priceTemplateUnitPriceCalculated}
                header={t('UnitPriceCalculated')}
              ></Column>
              <Column
                field="LineTotalCalculated"
                hidden={!hidden}
                className="text-bold"
                style={{ width: '10%' }}
                body={priceTemplateLineTotalCalculated}
                header={t('Total')}
              ></Column>
              <Column body={actionBodyTemplate} hidden={hidden} exportable={false}></Column>
            </DataTable>
            <ScrollTop />
          </div>
        </div>
        <div className="col-12 md:col-2" hidden={hidden}>
          <div className="card card-w-title global-sales ui-fluid">
            <div className="text-900 font-medium text-xl mb-2">{t('OrderSummary')}</div>
            <div className="text-800 font-medium">
              <b>{commonCtx.lineItems.length}</b> {t('ProductsInCart')}{' '}
            </div>
            <div className="text-500 font-medium mt-2">
              (<b>{commonCtx?.totalProductsInCart}</b> {t('ItemsInTotal')})
            </div>
            <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
            <div className="flex align-items-center">
              {t('Subtotal')}
              <span className="font-bold text-2xl text-900 ml-2">
                {commonCtx?.activeCart?.Subtotal
                  ? formatPrice(commonCtx?.activeCart?.Subtotal, commonCtx.locale)
                  : '-'}
              </span>
            </div>

            {!Cookies.get('isSalesAdmin') && (
              <button
                aria-label={t('Checkout')}
                disabled={commonCtx.lineItems.length === 0 ? true : false}
                className="p-button p-component p-3 w-full mt-4"
                onClick={() => goToCheckout()}
              >
                <span className="p-button-label p-c">{t('GoToCheckout')}</span>
              </button>
            )}
            <button
              aria-label={t('DeleteCart')}
              disabled={commonCtx.LineItemCount === 0 ? true : false}
              className="p-button p-component p-3 w-full mt-4 p-button-error"
              onClick={() => commonCtx.deleteCart()}
            >
              <span className="p-button-label p-c">{t('DeleteCart')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
