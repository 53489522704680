import React from 'react';
import { useTranslation } from 'react-i18next';

import SourcedRSSComponent from '../SourcedRSSComponent';

const NewsWidget = (props) => {
  const { t } = useTranslation();
  const url = props.rssURL;

  return (
    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round height280">
      <div className="surface-0  ">
        <h5 className="pl-3 pt-3">{t('LatestNews')}</h5>
        {url && (
          <SourcedRSSComponent
            url={url}
            subscribe={true}
            loadingComponent={() => <div className=" p-2 pl-3">{t('Loading')}</div>}
            errorComponent={(e) => {
              console.error(e);
              return <div className="failedNews">{t('FailedToLoadNews')}</div>;
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NewsWidget;
