import React, { useContext, useEffect } from 'react';
import MyOrdersSummary from '../MyOrdersSummary';
import UploadWidget from '../UploadWidget';
import NewsWidget from '../NewsWidget';
import MyUsers from '../MyUsers';
import { useTranslation } from 'react-i18next';
import CommonApi from '../CommonApi';
import Cookies from 'js-cookie';

const Home = (props) => {
  const { t } = useTranslation();
  const commonCtx = useContext(CommonApi);

  useEffect(() => {
    document.title = commonCtx.title + ` - ${t('Home')}`;
  }, []);

  return (
    <>
      {commonCtx.isSeller === false && (
        <>
          <div className="grid ">
            <MyOrdersSummary
              userInfo={props.userInfo}
              catalog={props.catalog}
              customerCode={props.customerCode}
              favorites={props.favorites}
            ></MyOrdersSummary>
          </div>
          {!Cookies.get('isSalesAdmin') && (
            <div className="grid ">
              <div className="col-12 md:col-6 lg:col-6">
                <NewsWidget rssURL={props.rssLink}></NewsWidget>
              </div>

              <div className="col-12 md:col-6 lg:col-6   ">
                <UploadWidget></UploadWidget>
              </div>
            </div>
          )}
          {Cookies.get('isSalesAdmin') && (
            <div className="grid ">
              <div className="col-12 md:col-12 lg:col-12">
                <NewsWidget rssURL={props.rssLink}></NewsWidget>
              </div>
            </div>
          )}
        </>
      )}

      {commonCtx.isSeller === true && (
        <>
          <MyUsers></MyUsers>
        </>
      )}
    </>
  );
};
export default Home;
