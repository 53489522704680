import React, { useContext, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import CommonApi from '../CommonApi';
import Breadcrumbs from '../Breadcrumbs';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const Profile = function (props) {
  const commonCtx = useContext(CommonApi);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const userInfo = commonCtx.me;

  useEffect(() => {
    if (!commonCtx.loading && loading) {
      if (!commonCtx.isLoggedIn) {
        location.assign('/' + Cookies.get('languageID') + '/');
      } else {
        commonCtx.listAddresses();
        setLoading(false);
      }
    }
    document.title = commonCtx.title + ` - ${t('Profile')}`;
  });

  return (
    <div>
      <Breadcrumbs leaf={t('MyProfile')}></Breadcrumbs>
      <div className="col-12 surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div className="p-fluid formgrid grid">
          <div className="field col-6 md:col-3">
            <label htmlFor="firstname2">{t('Username')}</label>
            <InputText id="firstname2" type="text" readOnly value={userInfo?.Username} />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="firstname2">{t('Email')}</label>
            <InputText id="firstname2" type="text" readOnly value={userInfo?.Email} />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="firstname2">{t('Firstname')}</label>
            <InputText id="firstname2" type="text" readOnly value={userInfo?.FirstName} />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="firstname2">{t('Lastname')}</label>
            <InputText id="firstname2" type="text" readOnly value={userInfo?.LastName} />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="phone">{t('Phone')}</label>
            <InputText id="phone" value={userInfo?.Phone} readOnly type="text" />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="city">{t('CustomerRelationship')}</label>
            <InputText
              id="relationship"
              value={commonCtx.customerCareRelationship}
              readOnly
              type="text"
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="zip">{t('Company')}</label>
            <InputText id="company" value={commonCtx.catalog?.Name} readOnly type="text" />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="zip">{t('CustomerCode')}</label>
            <InputText id="companycode" value={commonCtx.customerCode} readOnly type="text" />
          </div>
        </div>
      </div>
      <div className="col-12 surface-card shadow-2 p-3 border-1 border-50 border-round mt-2">
        <div className="grid">
          <div className="col-12 mt-4 lg:mt-0 mb-4">
            <div className="text-900 font-medium text-xl">{t('ShippingAddress')}</div>
          </div>
          <div className="col-12 field mb-0">
            <label htmlFor="addresses" className="text-900 font-medium mb-3"></label>
            <Dropdown
              value={commonCtx.selectedShippingAddress?.ID}
              onChange={(e) => {
                commonCtx.selectShippingAddress(e.value);
              }}
              className="p-inputtext w-full mb-3"
              options={commonCtx.addresses.filter((address) => address.Billing === false)}
              optionLabel="label"
              placeholder={t('ChooseAddress')}
            />
          </div>

          <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="street1" className="text-900 font-medium mb-3">
              {t('Street 1')}
            </label>
            <input
              id="name2"
              readOnly
              type="text"
              value={
                commonCtx.selectedShippingAddress?.Street1
                  ? commonCtx.selectedShippingAddress?.Street1
                  : ''
              }
              className="p-inputtext w-full mb-3"
            />
          </div>
          <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="street2" className="text-900 font-medium mb-3">
              {t('Street 2')}
            </label>
            <input
              id="surname2"
              readOnly
              value={
                commonCtx.selectedShippingAddress?.Street2
                  ? commonCtx.selectedShippingAddress?.Street2
                  : ''
              }
              type="text"
              className="p-inputtext w-full mb-3"
            />
          </div>
          <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="pc2" className="text-900 font-medium mb-3">
              {t('Postal Code')}
            </label>
            <input
              id="zip"
              type="text"
              readOnly
              value={
                commonCtx.selectedShippingAddress?.Zip ? commonCtx.selectedShippingAddress?.Zip : ''
              }
              className="p-inputtext w-full mb-3"
            />
          </div>
          <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="city2" className="text-900 font-medium mb-3">
              {t('City')}
            </label>
            <input
              id="city2"
              value={
                commonCtx.selectedShippingAddress?.City
                  ? commonCtx.selectedShippingAddress?.City
                  : ''
              }
              readOnly
              type="text"
              className="p-inputtext w-full mb-3"
            />
          </div>
          <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="pc2" className="text-900 font-medium mb-3">
              {t('Phone')}
            </label>
            <input
              id="phone"
              type="text"
              readOnly
              value={
                commonCtx.selectedShippingAddress?.Phone
                  ? commonCtx.selectedShippingAddress?.Phone
                  : ''
              }
              className="p-inputtext w-full mb-3"
            />
          </div>
          <div className="col-12 lg:col-6 field mb-0">
            <label htmlFor="city2" className="text-900 font-medium mb-3">
              {t('Country')}
            </label>
            <input
              id="country2"
              readOnly
              type="text"
              value={
                commonCtx.selectedShippingAddress?.Country
                  ? commonCtx.selectedShippingAddress?.Country
                  : ''
              }
              className="p-inputtext w-full mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
