import React, { useContext, useState } from 'react';
import CommonApi from '../CommonApi';
import { goToCart, goToProfile } from '../Helper';
import Cookies from 'js-cookie';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'primereact/progressbar';

const HeaderInfo = (props) => {
  const { t } = useTranslation();
  const languages = [
    { label: t('English'), value: 'en' },
    { label: t('Italian'), value: 'it' },
    { label: t('French'), value: 'fr' },
    { label: t('Chinese'), value: 'zh' },
    { label: t('Spanish'), value: 'es' },
    { label: t('German'), value: 'de' },
    // { label: t('Russia'), value: 'ru' },
    { label: t('Portuguese'), value: 'pt' },
    // { label: t('Polish'), value: 'pl' },
    // { label: t('Greek'), value: 'el' },
    // { label: t('Bulgarian'), value: 'bg' },
    // { label: t('Turkish'), value: 'tr' },
    // { label: t('Romanian'), value: 'ro' },
    // { label: t('Hungarian'), value: 'hu' },
    // { label: t('Chinese'), value: 'zh' },
  ];
  const [loading, setLoading] = useState(true);
  const commonCtx = useContext(CommonApi);
  // const [headerInfoHtml, setHeaderInfoHtml] = useState(<ul className="topbar-menu" />);

  const userInfo = commonCtx.me;

  return (
    <>
      <div className="overlayUpload" hidden={!commonCtx.loadingUpload}>
        <span className="textUpload">{t('UploadingFileProgress')} : &nbsp;</span>{' '}
        <span>
          <ProgressBar value={commonCtx.valueUpload}></ProgressBar>
        </span>
      </div>
      <ul className="topbar-menu">
        {commonCtx.isLoggedIn && (
          <li className="user-profile" onClick={() => goToProfile()}>
            <button type="button" className="p-link">
              <div className="layout-profile-userinfo">
                <span className="layout-profile-name">
                  {userInfo?.FirstName} {userInfo?.LastName}
                </span>
                <span className="layout-profile-role">{userInfo?.Email}</span>
              </div>
            </button>
          </li>
        )}
        {!Cookies.get('isSalesAdmin') &&
          commonCtx.isSeller === false &&
          commonCtx.isLoggedIn &&
          !commonCtx.loadingCart && (
            <li>
              <div type="button" className="p-link layout-topbar-icon" onClick={() => goToCart()}>
                <i className="topbar-icon pi pi-fw pi-shopping-cart  mr-4 p-overlay-badge">
                  <span className="p-badge p-component">{commonCtx?.LineItemCount}</span>
                </i>
              </div>
            </li>
          )}

        {!Cookies.get('isSalesAdmin') &&
          commonCtx.isSeller === false &&
          commonCtx.isLoggedIn &&
          commonCtx.loadingCart && (
            <li>
              <div type="button" className="p-link layout-topbar-icon" onClick={() => goToCart()}>
                <ProgressSpinner
                  style={{ width: '40px', height: '40px' }}
                  strokeWidth="5"
                  fill="transparent"
                  animationDuration=".5s"
                ></ProgressSpinner>
              </div>
            </li>
          )}

        <li className="user-profile mt-2 textWhite">
          <Dropdown
            className="languageDropdown"
            value={Cookies.get('languageID')}
            onChange={(e) => {
              commonCtx.changeLanguage(e.value);
            }}
            options={languages}
            optionLabel="label"
          ></Dropdown>
        </li>
      </ul>
    </>
  );
};
export default HeaderInfo;
