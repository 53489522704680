import React from 'react';
import { NavLink } from 'react-router-dom';
import Menu from '../Menu';
import logo from './../../assets/logo.png';
import HeaderInfo from '../HeaderInfo';
import Cookies from 'js-cookie';

export default function Header(props) {
  return (
    <div className="layout-wrapper layout-slim layout-menu-light p-input-filled p-ripple-disabled layout-topbar-blue">
      <div className="layout-topbar  col-12">
        <NavLink to={'/' + Cookies.get('languageID') + '/'} className="text-dark">
          <img
            className="mt-1"
            src={
              props?.fields?.Logo?.value?.src &&
              props?.fields?.Logo?.value?.src.indexOf('dayco//') === -1
                ? props?.fields?.Logo?.value?.src
                : logo
            }
            alt="Dayco B2B Ecommerce"
          />
        </NavLink>
        <HeaderInfo></HeaderInfo>
      </div>
      <Menu></Menu>
    </div>
  );
}
