import React, { useState, useContext, useEffect, useRef } from 'react';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Breadcrumbs from '../Breadcrumbs';
import CommonApi from '../CommonApi';
import { useTranslation } from 'react-i18next';

const Help = (props) => {
  const { t } = useTranslation();
  const list = props.fields.List;
  const commonCtx = useContext(CommonApi);

  useEffect(() => {
    document.title = commonCtx.title + ` - ${t('Help')}`;
  }, []);

  return (
    <div>
      <Breadcrumbs leaf={t('Help')}></Breadcrumbs>
      <div className="col-12 surface-card shadow-2 border-round p-2 mb-4">
        <div className="surface-section text-800 px-4 py-6 md:px-6">
          <div className="grid">
            <div className="col-12 md:col-3">
              <span className="block font-bold text-3xl mb-3">{props.fields.Title.value}</span>
              <div className="text-700 line-height-3">{props.fields.Description.value}</div>
            </div>
            <div className="col-12 md:col-9 md:px-5">
              <Accordion activeIndex={0}>
                {list.map((item, i) => {
                  return (
                    <AccordionTab key={i} header={item.fields.Title.value}>
                      <div
                        className="line-height-3"
                        dangerouslySetInnerHTML={{ __html: item.fields.Description.value }}
                      ></div>
                    </AccordionTab>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
