import Cookies from 'js-cookie';

export const stripTags = (original) => {
  return original.replace(/(<([^>]+)>)/gi, '');
};

export const isDev = () => {
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    return true;
  }
  return false;
};

export const goTo = (url) => {
  location.assign('/' + Cookies.get('languageID') + url);
  return;
};
export const goToHome = () => {
  location.assign('/');
};

export const goToProduct = (idProduct) => {
  goTo('/product/?productID=' + idProduct);
};

export const goToCategory = (idCategory) => {
  goTo('/productslist/?categoryID=' + window.btoa(idCategory));
};

export const goToOrders = (status) => {
  if (status) {
    goTo('/myorders/?status=' + status);
  } else goTo('/myorders/');
};

export const goToFavorites = (idCategory) => {
  goTo('/productslist/?categoryID=favorites');
};

export const goToCheckout = () => {
  goTo('/checkout');
  return;
};

export const goToSearch = (searchterm) => {
  goTo('/productslist/?search=' + searchterm);
};

export const goToCart = () => {
  goTo('/cart');
  return;
};

export const goToProfile = () => {
  goTo('/profile');
  return;
};

export const goToResetPassword = () => {
  goTo('/resetpassword');
  return;
};

export const autocomplete = (inp, arr) => {
  /* execute a function when someone clicks in the document:*/
  document.addEventListener('click', function (e) {
    closeAllLists(e.target);
  });

  if (inp) {
    /* the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /* execute a function when someone writes in the text field:*/
    inp.addEventListener('input', function (e) {
      var a,
        b,
        i,
        val = this.value;
      /* close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) {
        return false;
      }
      currentFocus = -1;
      /* create a DIV element that will contain the items (values):*/
      a = document.createElement('DIV');
      a.setAttribute('id', this.id + 'autocomplete-list');
      a.setAttribute('class', 'autocomplete-items');
      /* append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /* for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /* check if the item starts with the same letters as the text field value:*/
        if (arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
          /* reate a DIV element for each matching element:*/
          b = document.createElement('DIV');
          /* make the matching letters bold:*/
          b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + '</strong>';
          b.innerHTML += arr[i].substr(val.length);
          /* insert a input field that will hold the current array item's value:*/
          b.innerHTML += '<input type="hidden" value="' + arr[i] + '">';
          /* execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener('click', function (e) {
            /* insert the value for the autocomplete text field:*/
            inp.value = this.getElementsByTagName('input')[0].value;

            const myTimeout = setTimeout(window.location.assign('Product.html'), 1200);
            /* close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
            closeAllLists();
          });
          a.appendChild(b);
        }
      }
    });

    /* execute a function presses a key on the keyboard:*/
    inp.addEventListener('keydown', function (e) {
      var x = document.getElementById(this.id + 'autocomplete-list');
      if (x) x = x.getElementsByTagName('div');
      if (e.keyCode === 40) {
        /* If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /* and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode === 38) {
        // up
        /* If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /* and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode === 13) {
        /* If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /* and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
    });
  }
};

function removeActive(x) {
  /* a function to remove the "active" class from all autocomplete items:*/
  for (var i = 0; i < x.length; i++) {
    x[i].classList.remove('autocomplete-active');
  }
}
function closeAllLists(elmnt) {
  /* close all autocomplete lists in the document,
  except the one passed as an argument:*/
  var x = document.getElementsByClassName('autocomplete-items');
  for (var i = 0; i < x.length; i++) {
    if (elmnt !== x[i] && elmnt !== inp) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
}

export const formatDate = (value, time, locale) => {
  value = new Date(Date.parse(value));

  if (!value) return '';
  else {
    if (time) {
      return (
        value.toLocaleDateString(locale.Language) + ' ' + value.toLocaleTimeString(locale.Language)
      );
    } else return value.toLocaleDateString(locale.Language);
  }
};

export const formatPrice = (value, locale) => {
  if (!value) return '';
  if (!locale) return '';

  value = Number(value);

  return (
    new Intl.NumberFormat(locale.Language, {
      style: 'currency',
      currency: locale.Currency,
    })
      .format(value.toFixed(2))
      // if the price begins with digit, place the space after the digit
      .replace(/^([\d,.]+)/, '$1 ')
      // if the price ends with digit, place the space before the digit
      .replace(/([\d,.]+)$/, ' $1')
  );
};

function Helper() {}

export default Helper = (props) => '';
