import React, { useState, useContext, useEffect } from 'react';
import { Button } from 'primereact/button';
import CommonApi from '../CommonApi';
import Login from '../Login/';
import Home from '../Home/';
import FixedImage from '../../assets/webcatalog.jpg';
import { useTranslation } from 'react-i18next';

export const Main = (props) => {
  let heroBanner = FixedImage;

  const commonCtx = useContext(CommonApi);

  const [showLogin, setShowLogin] = useState(0);
  const { t } = useTranslation();

  const renderLoginForm = () => {
    return <Login stateLoginChanger={setShowLogin} props={props?.fields} />;
  };

  const renderLandingPage = () => {
    return (
      <div className="col-12 md:col-6 p-6 md:text-left flex align-items-center ">
        <section>
          <span className="block text-6xl font-bold mb-8">
            {props?.['Title Form']?.value ? props?.['Title Form']?.value : 'Dayco B2B eCommerce'}
          </span>
          <span className="block text-5xl font-bold mb-1">
            {props?.fields?.Title?.value
              ? props?.fields?.Title?.value
              : 'Deliver innovative system solutions'}
          </span>
          <div className="text-5xl text-primary font-bold mb-3">
            {props?.fields?.Subtitle?.value ? props?.fields?.Subtitle?.value : 'forward, always'}
          </div>
          <p className="mt-0 mb-4 text-700 line-height-3">
            {props?.fields?.Description?.value
              ? props?.fields?.Description?.value
              : 'The best performance for the best performers.'}
          </p>
          <Button
            label={t('Login')}
            onClick={() => setShowLogin(1)}
            type="button"
            className="mr-3 p-button-raised "
          />
        </section>
      </div>
    );
  };

  // if coming from sitecore I take the image
  if (props.fields.Image && props.fields.Image.value.src) {
    heroBanner = props.fields.Image.value.src;
  }

  if (!commonCtx.isLoggedIn) {
    return (
      <div className="col-12 surface-card shadow-2 border-round p-4 ">
        <div className="grid   surface-0 text-800  ">
          {showLogin ? renderLoginForm() : renderLandingPage()}
          <div className="hidden md:block col-6  md:text-right flex align-items-center  ">
            <img
              src={props?.fields?.Image?.value?.src ? props?.fields?.Image?.value?.src : heroBanner}
              alt="hero-2"
              style={{
                clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)',
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    const userInfo = commonCtx.me;
    const catalog = commonCtx.catalog;
    const customerCode = commonCtx.customerCode ? commonCtx.customerCode : '-';
    const favorites = commonCtx.favorites;
    const rssLink = props?.fields?.['Rss Link']?.value?.url;
    return (
      <Home
        userInfo={userInfo}
        catalog={catalog}
        customerCode={customerCode}
        favorites={favorites}
        rssLink={rssLink}
      ></Home>
    );
  }
};

export default Main;
