import React, { useRef, useContext, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import CommonApi from '../CommonApi';
import { useTranslation } from 'react-i18next';
import { goToResetPassword, goToHome } from '../Helper';
import { EnvironmentConfig } from '../../environments/environmentconfig';
import Cookies from 'js-cookie';

const Login = ({ stateLoginChanger, props, ...rest }) => {
  const [resetPwd, setResetPwd] = useState(props.resetPwd ? props.resetPwd : false);
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const checked = true;
  const commonCtx = useContext(CommonApi);
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  const newPasswordInputRef = useRef();
  const rememberMeInputRef = useRef();
  const codeInputRef = useRef();
  let username = Cookies.get('username') ? Cookies.get('username') : null;

  if (window.location.pathname.indexOf('reset') !== -1) {
    username = new URLSearchParams(window.location.search).get('username')
      ? new URLSearchParams(window.location.search).get('username')
      : null;
  }

  const password = Cookies.get('password') ? Cookies.get('password') : null;
  const [disabledSignInUser, setDisabledSignInUser] = useState(username ? false : true);
  const [disabledSignInPassword, setDisabledSignInPassword] = useState(password ? false : true);

  const submitHandler = (event) => {
    event.preventDefault();
    commonCtx.onLogin(
      usernameInputRef.current.value,
      passwordInputRef.current.value,
      rememberMeInputRef.current.props.checked,
      EnvironmentConfig.buyerClientId
    );
  };

  return (
    <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
      <section className="w-full maxwidth500">
        <span className="block  mb-1">
          {!resetPwd && (
            <div className="mb-5">
              <div className="text-900 text-3xl font-medium mb-3">{t('Welcome')}</div>
            </div>
          )}
          {resetPwd && (
            <div className="mb-5">
              <div className="text-900 text-3xl font-medium mb-3">
                {props?.['Title Reset']?.value ? props?.['Title Reset']?.value : t('ResetPassword')}
              </div>
              <span className="text-600 font-medium mr-2">
                {props?.['Subtitle Reset']?.value
                  ? props?.['Subtitle Reset']?.value
                  : t('SubtitleResetPassword')}
              </span>
            </div>
          )}
          <div>
            <form onSubmit={submitHandler}>
              {
                // VIEW LOGIN & PWD NORMAL
                !resetPwd && !commonCtx.newPassword && (
                  <>
                    <label htmlFor="usernameLabel" className="block text-900 font-medium mb-2">
                      {t('Username')}
                    </label>
                    <InputText
                      placeholder={t('InsertUsername')}
                      autoComplete="on"
                      autoCapitalize="off"
                      autoFocus="on"
                      id="username"
                      defaultValue={username}
                      readOnly={commonCtx.newPassword}
                      ref={usernameInputRef}
                      className="w-full mb-3"
                      onChange={(e) => setDisabledSignInUser(e.value === '')}
                    />
                    <label htmlFor="password2" className="block text-900 font-medium mb-2">
                      {t('Password')}
                    </label>
                    <InputText
                      ref={passwordInputRef}
                      autoComplete="on"
                      id="password"
                      defaultValue={password}
                      placeholder={t('InsertPassword')}
                      type="password"
                      className="w-full mb-3"
                      onChange={(e) => setDisabledSignInPassword(e.value === '')}
                    />
                  </>
                )
              }

              {
                // VIEW FORGOT PWD
                resetPwd && (
                  <>
                    <label htmlFor="usernameLabel" className="block text-900 font-medium mb-2">
                      {t('Username') ? t('Username') : 'Username'}
                    </label>
                    <InputText
                      placeholder={t('InsertUsername')}
                      autoComplete="on"
                      autoCapitalize="off"
                      autoFocus="on"
                      onChange={(e) => setDisabledSignInUser(e.value === '')}
                      defaultValue={username}
                      readOnly={commonCtx.newPassword}
                      disabled={username ? true : false}
                      ref={usernameInputRef}
                      className="w-full mb-3"
                    />
                    <label
                      hidden={!commonCtx.newPassword}
                      htmlFor="email2"
                      className={`text-900 font-medium mb-2 ${
                        !commonCtx.newPassword ? 'hidden' : 'block'
                      }`}
                    >
                      {t('VerificationCode') ? t('VerificationCode') : 'Verification Code'}
                    </label>
                    <InputText
                      placeholder={t('InsertVerificationCode')}
                      id="verificationCode"
                      hidden={!commonCtx.newPassword}
                      ref={codeInputRef}
                      className="w-full mb-3"
                    />
                  </>
                )
              }

              {commonCtx.newPassword && (
                <>
                  <label htmlFor="password2" className="block text-900 font-medium mb-2">
                    {t('NewPassword') ? t('NewPassword') : 'New Password'}
                  </label>
                  <InputText
                    ref={newPasswordInputRef}
                    autoComplete="on"
                    id="newPassword"
                    placeholder={t('InsertNewPassword')}
                    type="password"
                    className="w-full mb-3"
                    onChange={(e) => setDisabledSignInPassword(e.value === '')}
                  />
                </>
              )}

              {
                // REMEMBER ME ON LOGIN & PWD NORMAL
                !resetPwd && !commonCtx.newPassword && (
                  <div className="flex align-items-center justify-content-between mb-6">
                    <div className="flex align-items-center">
                      <Checkbox
                        id="rememberme2"
                        className="mr-2"
                        ref={rememberMeInputRef}
                        checked={checked}
                      />
                      <label htmlFor="rememberme2">{t('Rememberme')}</label>
                    </div>
                    <a
                      className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
                      onClick={() => goToResetPassword()}
                    >
                      {t('ResetPassword')} ?
                    </a>
                  </div>
                )
              }

              <div className="flex w-full justify-content-between ">
                {
                  // VIEW SIGN IN ONLY ON LOGIN & PWD NORMAL
                  !resetPwd && !commonCtx.newPassword && (
                    <>
                      <Button
                        label={t('Signin')}
                        icon="pi pi-user"
                        type="submit"
                        className="p-button-raised"
                        disabled={disabledSignInUser || disabledSignInPassword}
                      />
                      <Button
                        label={t('Back')}
                        type="button"
                        icon="pi pi-angle-left"
                        onClick={() => stateLoginChanger(false)}
                        className="p-button-outlined"
                      />
                    </>
                  )
                }

                {resetPwd && !commonCtx.newPassword && (
                  <Button
                    label={t('SendVerificationCode')}
                    disabled={disabledSignInUser}
                    onClick={() => {
                      commonCtx.sendVerificationCode(usernameInputRef.current.value);
                    }}
                    icon="pi pi-envelope"
                    type="button"
                    className="p-button-raised"
                  />
                )}
                {commonCtx.newPassword && (
                  <Button
                    label={t('SaveNewPassword')}
                    onClick={() => {
                      commonCtx.saveNewPassword(
                        usernameInputRef.current.value,
                        newPasswordInputRef.current.value,
                        codeInputRef.current.value
                      );
                    }}
                    icon="pi pi-code"
                    type="button"
                    className="p-button-raised"
                  />
                )}
                {resetPwd && !username && (
                  <Button
                    label={t('BackToLogin')}
                    type="button"
                    onClick={() => {
                      goToHome();
                    }}
                    icon="pi pi-angle-left"
                    className="p-button-outlined"
                  />
                )}
              </div>
            </form>
          </div>
        </span>
      </section>
    </div>
  );
};

export default Login;
