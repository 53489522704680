import React, { useRef, useState, useContext, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import CommonApi from '../CommonApi';
import { Tokens } from 'ordercloud-javascript-sdk';
import { EnvironmentConfig } from '../../environments/environmentconfig';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

export const UploadWidget = () => {
  const [totalSize, setTotalSize] = useState(0);
  const [className, setClassname] = useState(
    'surface-0 shadow-2 p-3 border-1 border-50 border-round height280'
  );
  const toast = useRef(null);
  const commonCtx = useContext(CommonApi);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.pathname === '/' + Cookies.get('languageID') + '/upload') {
      document.title = commonCtx.title + ` - ${t('Upload')}`;
      setClassname('surface-0 shadow-2 p-3 border-1 border-50 border-round heightTotal');
    }

    if (!commonCtx.loading && commonCtx.connection === null && loading) {
      commonCtx.startSignalR();
    }

    if (!commonCtx.loading && loading) {
      if (!commonCtx.isLoggedIn) {
        location.assign('/' + Cookies.get('languageID') + '/');
      } else {
        commonCtx.listAddresses();
        setLoading(false);
      }
    }
  });

  const prepRequest = (event) => {
    let accessToken = Tokens.GetAccessToken();
    let tokenType = 'Bearer';
    event.xhr.setRequestHeader('Authorization', tokenType + ' ' + accessToken);
    commonCtx.setValueUpload(0);
    commonCtx.setLoadingUpload(true);
    Cookies.set('loadingUpload', true);
  };

  const chooseOptions = {
    iconOnly: false,
    label: t('ChooseFile'),
    className: 'custom-choose-btn ',
  };
  const uploadOptions = {
    icon: 'pi pi-fw pi-cloud-upload',
    iconOnly: false,
    label: t('UploadFile'),
    className: 'custom-upload-btn ',
  };
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: false,
    label: t('CancelFile'),
    className: 'custom-cancel-btn ',
  };

  return (
    <div className={className}>
      <div className="flex justify-content-between mb-3">
        <div className="surface-0  w-full h-auto ">
          <div className="flex justify-content-between mb-3">
            <Toast ref={toast}></Toast>

            <div className="w-full">
              <div className="text-xl text-900 font-medium mb-3">{t('UploadOrder')}</div>
              <Button
                label={t('DownloadTemplate')}
                icon="pi pi-download"
                className="buttonUpload"
                onClick={() => commonCtx.downloadTemplate()}
              ></Button>
              <FileUpload
                name="file"
                chooseOptions={chooseOptions}
                uploadOptions={uploadOptions}
                cancelOptions={cancelOptions}
                url={`${EnvironmentConfig.apiMiddlewareUrl}api/Cart/UploadOrder`}
                onBeforeSend={prepRequest}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                maxFileSize={1333333}
                emptyTemplate={
                  <p className="m-0">
                    {commonCtx.errorsUpload ? commonCtx.errorsUpload : t('DragDrop')}
                  </p>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UploadWidget;
