import React, { FunctionComponent, useContext } from 'react';
import rssEnhancer, { InjectionRSSProps } from 'react-rss';
import { Carousel } from 'primereact/carousel';
import { formatDate, stripTags, isDev } from '../Helper';
import CommonApi from '../CommonApi';
import { useTranslation } from 'react-i18next';

const SourcedRSSComponent = (props) => {
  const commonCtx = useContext(CommonApi);

  if (isDev) {
    console.log('props', props);
  }

  const lengthText = 220;
  const { t } = useTranslation();

  const newsTemplate = (news) => {
    // console.log('news', news);

    let textCutted =
      stripTags(news.description).length < lengthText
        ? stripTags(news.description)
        : stripTags(news.description).slice(0, lengthText) + ' ... ';

    return (
      <>
        <div className="mr-4">
          <div className="text-500 mb-1">
            {formatDate(news.pubDate, false, commonCtx.locale)} :{' '}
          </div>
          <div className="text-1000 font-bold"> {news.title}</div>
        </div>
        <div className="product-item ml-0 mr-4 mt-2  mb-1">
          <span> {textCutted} </span>

          <a
            href={news.guid}
            rel="noreferrer"
            target="_blank"
            className="hoverBlack"
            hidden={stripTags(news.description).length < lengthText}
          >
            {t('ReadMore')}
          </a>
        </div>
      </>
    );
  };

  return (
    <Carousel
      className="descriptionNews"
      value={props.rss.items}
      numVisible={2}
      numScroll={2}
      orientation="horizontal"
      itemTemplate={newsTemplate}
    />
  );
};

export default rssEnhancer(SourcedRSSComponent);
