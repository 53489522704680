import React, { useContext, useEffect, useState } from 'react';

import CommonApi from '../CommonApi';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { goToFavorites, goToSearch } from '../Helper';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
const Menu = (props) => {
  const [term, setTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const commonCtx = useContext(CommonApi);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      goToSearch(term);
    }
  };

  const menubarEndTemplate = () => {
    if (commonCtx.isLoggedIn) {
      return (
        <span className="p-input">
          <input
            type="text"
            value={term}
            onKeyDown={handleKeyPress}
            alt={t('Insert at least 3 chars to search')}
            placeholder={t('Search by product code')}
            onChange={(e) => {
              setTerm(e.target.value);
            }}
            className="p-inputtext p-component mr-2"
          />
          <Button
            icon="pi pi-search"
            disabled={!term || (term && term.length < 3)}
            className="p-button-raised"
            onClick={() => goToSearch(term)}
          />
        </span>
      );
    } else {
      return '';
    }
  };

  const homeMenu = [
    {
      label: t('Home'),
      url: '/' + Cookies.get('languageID') + '/',
      icon: 'pi pi-fw pi-home',
    },
    {
      label: t('Help'),
      icon: 'pi pi-fw pi-info-circle',
      url: '/' + Cookies.get('languageID') + '/help',
    },
  ];

  const sellerMenu = [
    {
      label: t('Home'),
      url: '/' + Cookies.get('languageID') + '/',
      icon: 'pi pi-fw pi-home',
    },
    {
      label: t('MyAccount'),
      icon: 'pi pi-fw pi-user',
      items: [
        {
          label: t('MyProfile'),
          url: '/' + Cookies.get('languageID') + '/profile',
          icon: 'pi pi-fw pi-user',
        },
        {
          label: t('MyUsers'),
          url: '/' + Cookies.get('languageID') + '/myusers',
          icon: 'pi pi-fw pi-users',
        },
      ],
    },
    {
      label: t('Help'),
      icon: 'pi pi-fw pi-info-circle',
      url: '/' + Cookies.get('languageID') + '/help',
    },
    {
      label: t('Quit'),
      command: () => {
        commonCtx.onLogout();
      },
      icon: 'pi pi-fw pi-sign-out',
    },
  ];

  const myusers =
    commonCtx?.me?.AvailableRoles?.find((val) => val === 'BuyerUserAdmin') === 'BuyerUserAdmin' ||
    commonCtx?.me?.AvailableRoles?.find((val) => val === 'BuyerUserReader') === 'BuyerUserReader'
      ? {
          label: t('MyUsers'),
          url: '/' + Cookies.get('languageID') + '/myusers',
          icon: 'pi pi-fw pi-users',
        }
      : {
          label: t('My Users'),
          url: '/' + Cookies.get('languageID') + '/myusers',
          icon: 'pi pi-fw pi-users',
          className: 'hidden',
        };

  const quitLink = Cookies.get('impersonate')
    ? {
        label:
          Cookies.get('impersonate') +
          ': ' +
          t('Quit') +
          ' ' +
          t('FromImpersonating') +
          ' ' +
          commonCtx.me?.Username,
        command: () => {
          commonCtx.onLogout();
        },
        icon: 'pi pi-fw pi-sign-out',
      }
    : {
        label: t('Quit'),
        command: () => {
          commonCtx.onLogout();
        },
        icon: 'pi pi-fw pi-sign-out',
      };

  let myAccountSubItems;

  if (Cookies.get('isSalesAdmin')) {
    myAccountSubItems = [
      {
        label: t('MyOrders'),
        icon: 'pi pi-fw pi-shopping-cart',
        url: '/' + Cookies.get('languageID') + '/myorders',
      },
      {
        label: t('MyProfile'),
        url: '/' + Cookies.get('languageID') + '/profile',
        icon: 'pi pi-fw pi-user',
      },
      myusers,
    ];
  } else {
    myAccountSubItems = [
      {
        hidden: Cookies.get('isSalesAdmin'),
        label: t('Favorites'),
        icon: 'pi pi-fw pi-heart',
        command: () => {
          goToFavorites();
        },
      },
      {
        label: t('MyOrders'),
        icon: 'pi pi-fw pi-shopping-cart',
        url: '/' + Cookies.get('languageID') + '/myorders',
      },
      {
        label: t('MyProfile'),
        url: '/' + Cookies.get('languageID') + '/profile',
        icon: 'pi pi-fw pi-user',
      },
      myusers,
    ];
  }

  const nestedMenuitems = [
    {
      label: t('Home'),
      url: '/' + Cookies.get('languageID') + '/',
      icon: 'pi pi-fw pi-home',
    },
    {
      label: t('Categories'),
      icon: 'pi pi-fw pi-table',
      // className: Cookies.get('isSalesAdmin') ? 'hidden' : '',
      items: commonCtx.formatCategoriesMenu,
    },
    {
      label: t('MyAccount'),
      icon: 'pi pi-fw pi-user',
      items: myAccountSubItems,
    },
    {
      label: t('UploadOrder'),
      hidden: Cookies.get('isSalesAdmin'),
      icon: 'pi pi-fw pi-upload',
      className: Cookies.get('isSalesAdmin') ? 'hidden' : '',
      url: '/' + Cookies.get('languageID') + '/upload',
    },
    {
      label: t('Help'),
      icon: 'pi pi-fw pi-info-circle',
      url: '/' + Cookies.get('languageID') + '/help',
    },
    quitLink,
  ];

  let menuItems = homeMenu;

  if (commonCtx.isLoggedIn) {
    if (commonCtx.isSeller) {
      menuItems = sellerMenu;
    } else menuItems = nestedMenuitems;
  }
  return (
    <div className="p-menubar p-component col-12 menuBlue">
      <Menubar model={menuItems} end={menubarEndTemplate}></Menubar>
    </div>
  );
};

export default Menu;
