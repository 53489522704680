import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Auth,
  Tokens,
  Me,
  Users,
  ForgottenPassword,
  Buyers,
  Cart,
  UserGroups,
} from 'ordercloud-javascript-sdk';
import { AppConfig } from '../../configs/appconfig';
import { EnvironmentConfig } from '../../environments/environmentconfig';
import { Orders } from 'ordercloud-javascript-sdk';
import { Cart as CartOrderCloud } from 'ordercloud-javascript-sdk';
import { IntegrationEvents } from 'ordercloud-javascript-sdk';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import { LineItems } from 'ordercloud-javascript-sdk';
import { goToOrders, goToCategory, goToHome, goToCart, isDev } from '../Helper';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import Cookies from 'js-cookie';
import * as signalR from '@microsoft/signalr';

const CommonApi = React.createContext();

export const CommonApiProvider = (props) => {
  if (!Cookies.get('languageID')) {
    Cookies.set('languageID', 'en', { expires: 365 });
  }

  const lang = Cookies.get('languageID');

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [blocked, setBlocked] = useState(false);
  const [loadingCart, setLoadingCart] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [valueUpload, setValueUpload] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userAuthToken, setUserAuthToken] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [me, setMe] = useState(null);
  const [language, setLanguage] = useState(lang);
  const [expandedRows, setExpandedRows] = useState(null);
  const [allexpandedRows, setAllExpandedRows] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [activeCart, setActiveCart] = useState(null);
  const [catalog, setCatalog] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [parentCategoryID, setParentCategoryID] = useState([]);
  const [formatCategoriesMenu, setFormatCategoriesMenu] = useState([]);
  const [groups, setGroups] = useState(null);
  const [customerCode, setCustomerCode] = useState('-');
  const [customerCareRelationship, setCustomerCareRelationship] = useState(null);
  const [productListLength, setProductListLength] = useState(null);
  const [products, setProducts] = useState(null);
  const [users, setUsers] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [selectedBillingAddressId, setSelectedBillingAddressId] = useState([]);
  const [newPassword, setNewPassword] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [Total, setTotal] = useState(0);
  const [LineItemCount, setLineItemCount] = useState(0);
  const [totalProductsInCart, setTotalProductsInCart] = useState(0);
  const [lineItems, setLineItems] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [calculatedOrder, setCalculatedOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [ordersexcel, setOrdersExcel] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [showStock, setShowStock] = useState('');
  const [addressId, setAddressId] = useState('');
  const [locale, setLocale] = useState('');
  const [dateFormat, setDateFormat] = useState('mm/dd/yy');

  const [isimpersonating, setIsImpersonating] = useState(Cookies.get('impersonate') ? true : false);
  const [isSeller, setIsSeller] = useState(false);
  const [errorsUpload, setErrorsUpload] = useState(null);
  const [title, setTitle] = useState('Dayco B2B eCommerce');
  const [connection, setConnection] = useState(null);
  const [product, setProduct] = useState(null);
  const [qty, setQty] = useState(null);
  const [orderToDuplicate, setOrderToDuplicate] = useState(null);
  const [customerPO, setCustomerPO] = useState(null);
  const [messages, setMessages] = useState([]);
  const [qtyExceeded, setQtyExceeded] = useState(false);
  const [confirmDuplicateOrder, setConfirmDuplicateOrder] = useState(false);

  const toast = useRef();

  useEffect(() => {
    const storedUserLoggedInInformation = Cookies.get('authtoken');
    // check the stored authtoken
    if (storedUserLoggedInInformation) {
      // IF ALREADY LOGGED CHECK USER INFO
      setIsLoggedIn(true);
      setUserAuthToken(storedUserLoggedInInformation);

      // DISPLAY UPLOAD
      if (isDev) {
        console.log(Cookies.get('loadingUpload'));
      }
      if (Cookies.get('loadingUpload') && Cookies.get('loadingUpload') === 'true') {
        startSignalR();
      }

      // AFTER LOGIN WORKFLOW
      afterLoginWorkflow();
    } else {
      setLoading(false);
      checkLanguage();
    }
  }, []);

  const getCatalog = (response) => {
    return new Promise((resolve, reject) => {
      Me.GetCatalog(response.Buyer.DefaultCatalogID)
        .then((catalog) => {
          setCatalog(catalog);
          resolve(catalog);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const startSignalR = () => {
    setLoading(false);

    const newConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(`${EnvironmentConfig.apiMiddlewareUrl}uploadOrder`, {
        accessTokenFactory: () => {
          return Tokens.GetAccessToken();
        },
      })
      .build();

    newConnection.on('taskStarted', (data) => {
      if (isDev) {
        console.log(data);
        console.log('task started');
      }
    });

    newConnection.on('taskProgressChanged', (data) => {
      setLoadingUpload(true);
      setValueUpload(data);
      if (data >= 100) {
        getCart(language);
        setLoadingUpload(false);
        Cookies.set('loadingUpload', false);
      }
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    newConnection.on('taskEnded', (res) => {
      setLoadingUpload(false);
      Cookies.set('loadingUpload', false);
      if (res.success) {
        showSuccess(t('FileUploaded'));
        setErrorsUpload(null);
      } else {
        let errors = [];
        let errorsUpload = [];
        res.errors.forEach((error) => {
          console.log(error.product, error.statusCode);
          errors.push(
            <div key={error.product}>
              {error.product}:{' '}
              {error.statusCode === 'QuantityNotAvailable'
                ? t('qreq') +
                  ' ' +
                  error.quantityRequired +
                  ' ' +
                  t('qsub') +
                  ' ' +
                  error.quantitySubmitted
                : t(error.statusCode)}
            </div>
          );
          errorsUpload.push(
            <div className="p-error">
              {error.product}:{' '}
              {error.statusCode === 'QuantityNotAvailable'
                ? t('qreq') +
                  ' ' +
                  error.quantityRequired +
                  ' ' +
                  t('qsub') +
                  ' ' +
                  error.quantitySubmitted
                : t(error.statusCode)}
            </div>
          );
        });
        setErrorsUpload(errorsUpload);
        showError(errors);
      }
    });

    newConnection
      .start()
      .then(() => {
        if (isDev) {
          console.log('Connection started!');
        }
        setConnection(newConnection);
      })
      .catch((err) => console.error('Error while establishing connection: ' + err));
  };

  const getUserGroups = (catalog) => {
    return new Promise((resolve, reject) => {
      Me.ListUserGroups({ search: catalog.ID, searchOn: 'ID' })
        .then((groups) => {
          setGroups(groups.Items);
          groups.Items.forEach((element) => {
            if (element?.xp && element?.xp.CustomerCareRelashionship) {
              setCustomerCareRelationship(element?.xp.CustomerCareRelashionship);
            }

            if (element?.xp && element?.xp.ShowStock) {
              setShowStock(element?.xp.ShowStock);
            }

            setAddressId(element?.xp.CompanyNumber + '-' + element?.xp.PlantCode?.Principal);

            if (element?.xp && element?.xp.Currency) {
              // set currency
              setCurrency(element?.xp.Currency);
            }
          });

          resolve(groups);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const getCategories = (language) => {
    return new Promise((resolve, reject) => {
      Me.ListCategories({ depth: 'all', pageSize: 100 })
        .then((res) => {
          res.Items.forEach((category) => {
            // handle translation categories
            if (category && category?.xp.Translation && category?.xp.Translation[language]) {
              category.Name = category?.xp.Translation[language];
            }
          });

          res.Items.sort((a, b) =>
            a.ListOrder > b.ListOrder ? 1 : b.ListOrder > a.ListOrder ? -1 : 0
          );

          setCategories(res.Items);

          const formatCategoriesMenu = [];

          if (res.Items) {
            // I make the first level
            res.Items.forEach((category, index) => {
              if (category.ParentID === null) {
                // THE FIRST LEVEL
                formatCategoriesMenu.push({
                  label: category?.Name,
                  ChildCount: category?.ChildCount,
                  id: category.ID,
                  items: [],
                  icon: 'pi pi-fw pi-folder',
                });
              }
            });

            function processCategory(res, toAdd) {
              res.Items.forEach((subelement) => {
                if (subelement.ParentID !== null && toAdd.id === subelement.ParentID) {
                  let newToAdd = {
                    label: subelement?.Name,
                    ChildCount: subelement?.ChildCount,
                    id: subelement.ID,
                    command: (me) => {
                      if (me.item.items) {
                      } else goToCategory(subelement.ID);
                    },
                    icon: subelement?.ChildCount > 0 ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-copy',
                  };

                  // FOR THIRD LEVEL
                  if (subelement.ChildCount > 0) {
                    newToAdd.items = [];
                    if (!toAdd.items) toAdd.items = [];
                    toAdd.items.push(processCategory(res, newToAdd));
                  } else {
                    if (!toAdd.items) toAdd.items = [];
                    toAdd.items.push(newToAdd);
                  }
                }
              });
              return toAdd;
            }

            // FOR FIRST CATEGORIES
            formatCategoriesMenu.forEach((category, index) => {
              if (category.ChildCount > 0) {
                res.Items.forEach((element, ind) => {
                  // NOT FIRST  LEVEL
                  if (element.ParentID !== null && category.id === element.ParentID) {
                    if (category.ChildCount === 0) {
                      res.Items.splice(ind, 1);
                    }

                    let toAdd = {
                      label: element?.Name,
                      ChildCount: element?.ChildCount,
                      id: element.ID,
                      command: (me) => {
                        if (me.item.items) {
                        } else goToCategory(element.ID);
                      },
                      icon: element.ChildCount > 0 ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-copy',
                    };

                    if (element.ChildCount > 0) {
                      // FOR SECOND CATEGORIES
                      toAdd.Items = [];
                      category.items.push(processCategory(res, toAdd));
                    } else {
                      category.items.push(toAdd);
                    }
                  }
                });
              }
            });

            setFormatCategoriesMenu(formatCategoriesMenu);

            setLoading(false);
          }

          resolve(res.Items);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const getMe = () => {
    return new Promise((resolve, reject) => {
      Me.Get()
        .then((response) => {
          if (response) {
            setMe(response);
          }
          if (response.xp && response.xp.FavoriteProducts) {
            setFavorites(response.xp.FavoriteProducts);
          }

          // SELLER CASE
          if (response.Buyer === null) {
            setIsSeller(true);
            setLoading(false);
          }

          // SALES ADMIN CASE
          if (response?.AvailableRoles?.find((val) => val === 'SAL_ADMIN') === 'SAL_ADMIN') {
            Cookies.set('isSalesAdmin', true);
          }

          if (response.xp === null) {
            response.xp = {};
          }

          if (response && response.xp && !response.xp.Language) {
            response.xp.Language = 'en';
          }

          if (response && response.Locale) {
            // LOCALE SCHIANTATO DA SISTEMARE
            if (response.Locale.Language === null || !response.Locale.Language) {
              response.Locale.Language = 'en-US';
            } else if (response.Locale.Language !== 'en-US') {
              setDateFormat('dd/mm/yy');
            }
            setLocale(response.Locale);
          }

          checkLanguage(response.xp.Language);
          setLanguage(response.xp.Language);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          handleApiError(error);
        });
    });
  };

  const getLineItemsFromOrder = (id, currentPage, filtersFE = null) => {
    let filters = {};
    if (filtersFE) {
      for (const [key, value] of Object.entries(filtersFE)) {
        if (value && value.constraints && value.constraints[0] && value.constraints[0].value) {
          filters[key] = value.constraints[0].value;
        }
      }
    }

    if (typeof currentPage === 'undefined') {
      currentPage = 1;
    }

    setLoading(true);
    setExpandedRows([]);
    setProductListLength(0);

    LineItems.List('all', id, { page: currentPage, pageSize: 100, filters: filters })
      .then((lineItems) => {
        setProductListLength(lineItems.Meta.TotalCount);
        orders.forEach((element) => {
          if (element.ID === id) {
            element.lineItems = lineItems.Items;
            // handle translation for products
            element.lineItems.forEach((prod) => {
              if (
                prod.Product &&
                prod.Product.xp.Translation &&
                prod.Product.xp.Translation[language]
              ) {
                prod.Product.Translation = prod.Product.xp.Translation[language];
              }
            });
            setExpandedRows(element.lineItems);
          }
        });
        setOrders(orders);
        setLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const listOrdersExcel = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      let filters = { Status: ['!Unsubmitted'] };

      let params = { filters: filters };

      if (
        me?.AvailableRoles?.find((val) => val === 'BuyerUserAdmin') === 'BuyerUserAdmin' ||
        (me?.xp.buyerVisibility && me?.xp.buyerVisibility === true)
      ) {
        Orders.List('All', {
          buyerID: me.Buyer.ID,
          page: 1,
          pageSize: 100,
          filters: filters,
        })
          .then((orders) => {
            if (isDev) {
              console.log('excel BUYER orders', orders);
            }

            if (orders.Meta.TotalCount > 100) {
              let times = Math.ceil(orders.Meta.TotalCount / 100);
              let ordersToAdd = [];
              ordersToAdd = orders.Items;

              let promises = [];
              for (let i = 1; i < times; i++) {
                let params = { page: i + 1, pageSize: 100, filters: filters, buyerID: me.Buyer.ID };
                promises.push(Orders.List('All', params));
              }

              Promise.all(promises).then((data) => {
                data.forEach((element) => {
                  ordersToAdd = ordersToAdd.concat(element.Items);
                });
                setLoading(false);
                setOrdersExcel(ordersToAdd);
                if (isDev) {
                  console.log('orders', ordersToAdd);
                }
                resolve(ordersToAdd);
              });
            } else {
              setLoading(false);
              setOrdersExcel(orders.Items);
              resolve(orders.Items);
            }
          })
          .catch((error) => {
            setLoading(false);
            handleApiError(error);
            reject(error);
          });
      } else {
        setLoading(true);
        Me.ListOrders(params)
          .then((orders) => {
            if (isDev) {
              console.log('orders', orders);
            }

            if (orders.Meta.TotalCount > 100 && pageSize === 100) {
              let times = Math.ceil(orders.Meta.TotalCount / 100);
              let ordersToAdd = [];
              ordersToAdd = orders.Items;

              let promises = [];
              for (let i = 1; i < times; i++) {
                let params = { page: i + 1, pageSize: 100, filters: status };
                promises.push(Me.ListOrders(params));
              }

              Promise.all(promises).then((data) => {
                data[0].Items.forEach((element) => {
                  ordersToAdd.push(element);
                });
                setLoading(false);
                setOrdersExcel(ordersToAdd);
                if (isDev) {
                  console.log('orders', orders);
                }
                resolve(ordersToAdd);
              });
            } else {
              setLoading(false);
              setOrdersExcel(orders.Items);
              resolve(orders.Items);
            }
          })
          .catch((error) => {
            setLoading(false);
            handleApiError(error);
            reject(error);
          });
      }
    });
  };

  const getLineItemsFromOrderExcel = (id) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      LineItems.List('all', id, { page: 1, pageSize: 100 })
        .then((lineItems) => {
          setProductListLength(lineItems.Meta.TotalCount);
          if (lineItems.Meta.TotalCount > 100) {
            let times = Math.ceil(lineItems.Meta.TotalCount / 100);
            let ordersToAdd = [];
            ordersToAdd = orders.Items;

            let promises = [];
            for (let i = 1; i < times; i++) {
              let params = { page: i + 1, pageSize: 100 };
              promises.push(LineItems.List('all', id, params));
            }

            let expandedlineItems = [];
            let pages = [];
            pages = pages.concat(lineItems.Items);

            Promise.all(promises).then((data) => {
              data.forEach((page) => {
                pages = pages.concat(page.Items);
              });

              pages.forEach((lineItems) => {
                if (
                  lineItems.Product &&
                  lineItems.Product.xp.Translation &&
                  lineItems.Product.xp.Translation[language]
                ) {
                  lineItems.Product.Translation = lineItems.Product.xp.Translation[language];
                }

                expandedlineItems.push(lineItems);
              });
              setAllExpandedRows(expandedlineItems);
              resolve(expandedlineItems);
              setLoading(false);
            });
          } else {
            setAllExpandedRows(lineItems.Items);
            resolve(lineItems.Items);
            setLoading(false);
          }
        })
        .catch((error) => {
          handleApiError(error);
        });
    });
  };

  const listOrders = (currentPage, pageSize, filtersFE) => {
    let filters = { Status: ['!Unsubmitted'] };
    if (filtersFE) {
      for (const [key, value] of Object.entries(filtersFE)) {
        if (value && value.constraints && value.constraints[0] && value.constraints[0].value) {
          filters[key] = value.constraints[0].value;
        }
      }
    }

    if (!pageSize) pageSize = 20;

    let params = { page: currentPage, pageSize: pageSize, filters: filters };

    if (
      me?.AvailableRoles?.find((val) => val === 'BuyerUserAdmin') === 'BuyerUserAdmin' ||
      (me?.xp.buyerVisibility && me?.xp.buyerVisibility === true)
    ) {
      setLoading(true);
      Orders.List('All', {
        buyerID: me.Buyer.ID,
        page: currentPage,
        pageSize: pageSize,
        filters: filters,
      })
        .then((orders) => {
          if (isDev) {
            console.log('BUYER orders', orders);
          }

          setTotalOrders(orders.Meta.TotalCount);

          setLoading(false);
          setOrders(orders.Items);
        })
        .catch((error) => {
          setLoading(false);
          handleApiError(error);
        });
    } else {
      setLoading(true);
      Me.ListOrders(params)
        .then((orders) => {
          if (isDev) {
            console.log('orders', orders);
          }

          setLoading(false);
          setTotalOrders(orders.Meta.TotalCount);

          setLoading(false);
          setOrders(orders.Items);
        })
        .catch((error) => {
          setLoading(false);
          handleApiError(error);
        });
    }
  };

  const selectShippingAddress = (id) => {
    updateShippingAddress(id);
  };

  const selectBillingAddress = (id) => {
    if (isDev) {
      console.log('selectedBillingAddressId', selectedBillingAddressId);
    }
    if (id !== selectedBillingAddressId) {
      updateBillingAddress(id);
    }
  };

  const listAddresses = (userToSetAddressesAssignment) => {
    setLoading(true);
    Me.ListAddresses()
      .then((addresses) => {
        if (isDev) {
          console.log('addresses', addresses);
        }

        addresses.Items.forEach((address) => {
          address.label =
            address.AddressName +
            ' - ' +
            address.Street1 +
            ', ' +
            address.City +
            ' ' +
            address.Zip +
            ', ' +
            address.Country;
          address.value = address.ID;

          /* if (address.Billing === true) {
            setCustomerCode(address?.xp.CustomerCode);
          }*/

          // assign new user to every address
          if (userToSetAddressesAssignment && address.ID) {
            // && address.Shipping) {
            if (isDev) {
              console.log(address.ID, userToSetAddressesAssignment.ID);
            }

            UserGroups.SaveUserAssignment(me.Buyer.ID, {
              UserGroupID: address.ID,
              UserID: userToSetAddressesAssignment.ID,
            });
          }
        });

        setAddresses(addresses.Items);

        /* if (activeCart && activeCart.ShippingAddressID) {
          setSelectedShippingAddress(
            addresses.Items.find((element) => element.ID === activeCart.ShippingAddressID)
          );
        }*/
        setLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const setCurrency = (currency) => {
    if (currency === 'EUR') setCurrencySymbol('€');
    else if (currency === 'GBP') setCurrencySymbol('£');
    else if (currency === 'CAD') setCurrencySymbol('$');
    else setCurrencySymbol('$');
  };

  const createOrder = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      Orders.Create('Outgoing', {})
        .then((newOrder) => {
          if (isDev) {
            console.log(newOrder);
          }
          resolve(true);
          setLoading(false);
        })
        .catch((error) => {
          handleApiError(error);
        });
    });
  };

  const downloadTemplate = () => {
    window.open('/DaycoUploadTemplate.xlsx', '_blank');
  };

  const updateUser = (id, params) => {
    setLoading(true);
    if (isDev) {
      console.log('params', params);
    }
    return new Promise((resolve, reject) => {
      Users.Patch(me.Buyer.ID, id, params)
        .then((user) => {
          if (isDev) {
            console.log('user', user);
          }
          setLoading(false);
          resolve(user);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const deleteUser = (id) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      Users.Delete(me.Buyer.ID, id)
        .then((user) => {
          if (isDev) {
            console.log('user', user);
          }
          setLoading(false);
          resolve(user);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const impersonateUser = (id, buyerId) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      Users.GetAccessToken(buyerId, id, {
        ClientID: EnvironmentConfig.buyerClientId,
        roles: [
          'MeAddressAdmin',
          'AddressAdmin',
          'MeAdmin',
          'MeCreditCardAdmin',
          'MeXpAdmin',
          'UserGroupAdmin',
          'ApprovalRuleAdmin',
          'BuyerUserAdmin',
          'Shopper',
          'BuyerReader',
          'PasswordReset',
          'SupplierReader',
          'SupplierAddressReader',
          'OrderAdmin',
          'OrderReader',
        ],
      })
        .then((token) => {
          if (isDev) {
            console.log('token', token);
          }
          Cookies.set('impersonateToken', Tokens.GetAccessToken());
          Tokens.SetAccessToken(token.access_token);
          setUserAuthToken(token.access_token);
          setIsLoggedIn(true);
          Cookies.set('authtoken', token.access_token);
          Cookies.set('impersonate', me.Username);
          setLoading(false);
          goToHome();
          resolve(token);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const createUser = (params) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      Users.Create(me.Buyer.ID, params)
        .then((user) => {
          setLoading(false);
          if (isDev) {
            console.log('user', user);
          }
          resolve(user);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const listUsers = (currentPage = 1, value = '') => {
    let filters = {
      'xp.Visible': true,
    };

    setLoading(true);
    return new Promise((resolve, reject) => {
      Users.List(me.Buyer.ID, {
        page: currentPage,
        pageSize: value ? 100 : 20,
        search: value ? value : '',
        searchOn: value ? ['Username', 'LastName', 'FirstName'] : '',
        filters: filters,
      })
        .then((users) => {
          if (isDev) {
            console.log('users', users);
          }
          users.Items.forEach((user, index) => {
            if (user.ID === me.ID) {
              users.Items.splice(index, 1);
            }
          });

          resolve(users.Items);
          setLoading(false);
          setUsers(users.Items);
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const listAllUsers = (currentPage = 1, value = '') => {
    const companies = me?.xp.Companies.join('||');
    setLoading(true);
    return new Promise((resolve, reject) => {
      Buyers.List({
        pageSize: 100,
        filters: {
          'xp.CompanyNumber': companies,
        },
      })
        .then((buyers) => {
          if (isDev) {
            console.log('buyers');
            console.log(buyers);
          }
          let allUsers = [];
          let promises = [];
          for (let i = 0; i < buyers.Items.length; i++) {
            if (
              me?.xp?.SuperAdmin ||
              // devono essere visibili solo i buyers che hanno company e CustomerCareRelationship uguali a quella
              // dell'utente Customer Care  se la Function è CSE
              me?.xp.Code === buyers.Items[i]?.xp.CustomerCareRelashionship ||
              // oppure company e almeno uno dei valori in CustomerService uguali se la Funtion è SAL
              buyers.Items[i]?.xp.CustomersService.indexOf(me?.xp.Code) !== -1
            ) {
              promises.push(
                Users.List(buyers.Items[i].ID, {
                  page: currentPage,
                  pageSize: value ? 100 : 20,
                  filters: {
                    search: value ? value : '',
                    searchOn: value ? ['CustomerCode', 'Username'] : '',
                    // non funziona questo filtro ...
                    AvailableRoles: ['BuyerUserAdmin'],
                    filters: { 'xp.Visible': true },
                  },
                })
              );
            }
          }
          Promise.all(promises)
            .then((data) => {
              for (let i = 0; i < data.length; i++) {
                data[i].Items.forEach((element) => {
                  for (let i = 0; i < buyers.Items.length; i++) {
                    if (element.CompanyID === buyers.Items[i].ID) {
                      element.CustomerCode = buyers.Items[i]?.xp.CustomerCode;
                      element.CompanyName = buyers.Items[i].Name;
                      element.CompanyNumber = buyers.Items[i]?.xp.CompanyNumber;
                    }
                  }

                  if (element.AvailableRoles.includes('BuyerUserAdmin')) {
                    allUsers.push(element);
                  }
                });
              }
              if (isDev) {
                console.log('allUsers', allUsers);
              }
              resolve(allUsers);
              setUsers(allUsers);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
        });
    });
  };

  const deleteCart = () => {
    setLoading(true);
    setLoadingCart(true);

    Cart.Delete().then(
      (res) => {
        getCart(language, categories);
      },
      (error) => {
        setLoadingCart(false);
        setLoading(false);
        handleApiError(error);
      }
    );
  };

  const duplicateOrder = (id) => {
    if (id) {
      setLoading(true);
      setBlocked(true);

      let url = `${EnvironmentConfig.apiMiddlewareUrl}/order`;
      url = url + '/' + id + '/duplicate';

      fetch(url, {
        method: 'POST',
        headers: { Authorization: `Bearer ${Tokens.GetAccessToken()}` },
      })
        .then((res) => {
          if (isDev) {
            console.log(res);
          }

          setBlocked(false);
          setLoading(false);

          if (res.status !== 200) {
            setLoadingCart(false);
            handleApiError(error);
          } else {
            // show success and go to my orders
            showSuccess(t('OrderDuplicated'));

            setTimeout(function () {
              goToCart();
            }, 3000);
          }
        })
        .catch(() => {
          showError(t('OrderNotDuplicated'));
          setBlocked(false);
          setLoading(false);
        });
    }
  };

  const setUserViewAllOrder = (userID, buyerID, viewAllOrderFlag) => {
    setLoading(true);
    let url = `${EnvironmentConfig.apiMiddlewareUrl}User`;
    // always add details
    url = url + '/ViewAllOrder?userID=' + userID + '&buyerID=' + buyerID;

    fetch(url, {
      method: viewAllOrderFlag ? 'PUT' : 'DELETE',
      headers: { Authorization: `Bearer ${Tokens.GetAccessToken()}` },
    })
      .then(
        (res) => {
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          handleApiError(error);
        }
      )
      .catch(() => {
        setLoading(false);
      });
  };

  const getCart = (language, cat) => {
    setLoading(false);
    setLoadingCart(true);
    let url = `${EnvironmentConfig.apiMiddlewareUrl}api/Cart`;
    // always add details
    url = url + '/CartFull';

    fetch(url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${Tokens.GetAccessToken()}` },
    })
      .then((res) => res.json())
      .then(
        (res) => {
          setProducts(res.Products);
          setOrderId(res.Order.ID);
          if (isDev) {
            console.log('activeCart', res);
          }

          // how many rows in cart
          setLineItemCount(res.LineItems.length);

          if (res.Order) {
            refactorLineItems(res.LineItems, res.Products, language, cat);
            res = res.Order;
          }
          setActiveCart(res);
          setTotal(res.Total);
          setLoadingCart(false);
        },
        (error) => {
          setLoadingCart(false);
          handleApiError(error);
        }
      )
      .catch(() => {
        setLoadingCart(false);
      });
  };

  const verifyCode = () => {
    return true;
  };

  const calculateOrder = (CustomerPO, Notes, RequestedDeliveryDate) => {
    setLoading(true);

    if (isDev) {
      console.log(
        'CustomerPO, Notes, RequestedDeliveryDate',
        CustomerPO,
        Notes,
        RequestedDeliveryDate
      );
    }
    let xp = {};
    xp.CustomerPO = CustomerPO;
    xp.Notes = Notes;
    xp.RequestedDeliveryDate = RequestedDeliveryDate;
    xp.ExpressDelivery = false;

    Orders.Patch('Outgoing', orderId, {
      xp: xp,
    })
      .then((res) => {
        if (orderId) {
          setLoading(true);
          IntegrationEvents.Calculate('Outgoing', orderId)
            .then((res) => {
              if (isDev) {
                console.log('CalculatedOrder', res);
              }
              setCalculatedOrder(res.Order);

              res.OrderCalculateResponse.LineItemOverrides.forEach((lineOverride) => {
                let line = lineItems.find((line) => line.ID === lineOverride.LineItemID);
                line.UnitPriceCalculated = lineOverride.UnitPrice;
                line.LineTotalCalculated = lineOverride.UnitPrice * line.Quantity;
              });

              setLoading(false);
            })
            .catch((error) => {
              handleApiError(error);
            });
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const refactorLineItems = (lineItems, products, language, cat) => {
    let categoriesForTranslation = cat ? cat : categories;

    // refactor some data
    lineItems.forEach((element, index) => {
      // handle translation products
      if (
        element.Product &&
        element.Product.xp.Translation &&
        element.Product.xp.Translation[language]
      ) {
        element.Product.Translation = element.Product.xp.Translation[language];
      }

      element.PriceSchedule = products[index].PriceSchedule;

      /*
      if (lineItems[index]?.xp && lineItems[index]?.xp.Inventory) {
        element.Product.Inventory = lineItems[index]?.xp.Inventory;
      } else {
        // no stock case
        element.Product.Inventory = null;
      }*/

      // handle translation categories
      let category = categoriesForTranslation.find(
        (category) => category.ID === element.Product.xp.Category
      );
      let subcategory = categoriesForTranslation.find(
        (category) => category.ID === element.Product.xp.SubCategory
      );

      if (category) {
        element.Product.xp.CategoryID = category.ID;
        element.Product.xp.Category = category?.Name;
      }

      if (subcategory) {
        element.Product.xp.SubCategoryID = subcategory.ID;
        element.Product.xp.SubCategory = subcategory?.Name;
      }

      element.Product.Inventory = element.Product.xp.Stock;

      element.Product = calculateStock(element.Product, addressId);
    });

    setLineItems(lineItems);
    let sum = 0;
    if (lineItems && lineItems.length > 0) {
      lineItems.forEach((element) => {
        sum = sum + element.Quantity;
      });
    }
    setTotalProductsInCart(sum);
    setLoading(false);
  };

  const addLineItem = (lineItem) => {
    setLoading(true);
    const lineItemWithMatchingSpecs = lineItems.find(
      (li) => li.ProductID === lineItem.ProductID
      // &&        this.hasSameSpecs(lineItem, li)
    );
    if (lineItemWithMatchingSpecs) {
      //  BLOCK WITH ALERT IF NOT IN CART

      showError(t('AlreadyPresent'));
      return false;
      // add quantity to lineItem
      modifyLineItem(
        lineItemWithMatchingSpecs.ID,
        lineItemWithMatchingSpecs.Product,
        lineItemWithMatchingSpecs.Quantity + lineItem.Quantity
      );
    } else if (lineItems.length >= 950) {
      showError(t('MaxLines'));
    } else {
      createLineItem(lineItem);
    }
  };

  const createLineItem = (newLineItem) => {
    setLoading(true);
    CartOrderCloud.CreateLineItem(newLineItem)
      .then((res) => {
        if (isDev) {
          console.log('CreateLineItem');
          console.log(res);
        }
        getCart(language);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const removeFromCart = (LineID) => {
    setLoading(true);
    CartOrderCloud.DeleteLineItem(LineID)
      .then((res) => {
        console.log('DeleteLineItem');
        console.log(res);
        getCart(language);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const calculateStock = (element, addressId) => {
    if (!element || !element.Inventory || !element?.xp.Stock || element?.xp.Stock.length === 0) {
      element.statusStock = t('undefinedstock');
      element.labelStock = 'NAStock';
    } else {
      let stock = element?.xp.Stock.filter((e) => e.AddressId === addressId);

      if (!stock || stock === undefined || stock.length === 0) {
        stock = element?.xp.Stock[0];
      } else {
        stock = stock[0];
      }

      // STOCK GREEN YELLOW RED

      switch (stock.AvailabilityStatus) {
        case 'Y':
          // LOW STOCK
          element.statusStock = t('lowstock');
          element.labelStock = 'LowStock';
          break;
        case 'G':
          // IN STOCK
          element.statusStock = t('instock');
          element.labelStock = 'InStock';
          break;
        case 'R':
          // NO STOCK
          element.statusStock = t('outofstock');
          element.labelStock = 'NoStock';
          break;
        default:
          // UNDEFINED
          element.statusStock = t('undefinedstock');
          element.labelStock = 'NAStock';
      }
    }
    return element;
  };

  const sendVerificationCode = (username) => {
    setLoading(true);
    const req = {
      ClientID: EnvironmentConfig.buyerClientId,
      Username: username,
      URL: window.location.origin,
    };
    ForgottenPassword.SendVerificationCode(req)
      .then((res) => {
        console.log('SendVerificationCode');
        console.log(res);
        setLoading(false);
        setNewPassword(true);
      })
      .catch((error) => {
        setLoading(false);
        setNewPassword(false);
        handleApiError(error);
      });
  };

  const saveNewPassword = (username, newPassword, code) => {
    setLoading(true);
    const req = {
      ClientID: EnvironmentConfig.buyerClientId,
      Username: username,
      Password: newPassword,
    };
    ForgottenPassword.ResetPasswordByVerificationCode(code, req)
      .then((res) => {
        console.log('ResetPasswordByVerificationCode');
        console.log(res);

        // show success and go to my orders
        showSuccess(t('PasswordSaved'));
        setTimeout(function () {
          // Do something after 5 seconds
          goToHome();
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error);
      });
  };

  const sendOrder = () => {
    setLoading(true);
    Orders.Submit('Outgoing', orderId)
      .then((res) => {
        console.log('Orders.Submit');
        console.log(res);

        // show success and go to my orders
        showSuccess(t('OrderSubmitted'));
        console.log('Orders.Patch');
        console.log(res);

        setTimeout(function () {
          goToOrders();
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        handleApiError(error);
      });
  };

  const getTranslationCategory = (categoryID) => {
    const translation = categories.find((category) => category.ID === categoryID);
    return translation?.Name;
  };

  const GetProduct = (productID, addressId, favorites = null) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      Me.GetProduct(productID)
        .then((product) => {
          console.log('product', product);

          // refactor some data

          // handle translation product single
          if (product && product?.xp.Translation && product?.xp.Translation[language]) {
            product.Translation = product?.xp.Translation[language];
          }

          // set price
          if (
            product.PriceSchedule &&
            product.PriceSchedule.PriceBreaks &&
            product.PriceSchedule.PriceBreaks.length > 0
          ) {
            product.price = product.PriceSchedule.PriceBreaks[0].Price;
            product.SalePrice = product.PriceSchedule.PriceBreaks[0].SalePrice;
          }

          setCategoryID(product?.xp.Category);

          // add thumbnail image
          if (product?.xp && product?.xp.Images && product?.xp.Images.length > 0) {
            product.thumbnail = product?.xp.Images[0]?.ThumbnailUrl;
            product.image = product?.xp.Images[0].Url;
          }

          // handle translation categories
          let category = categories.find((category) => category.ID === product?.xp.Category);
          let subcategory = categories.find((category) => category.ID === product?.xp.SubCategory);

          if (category) {
            product.xp.CategoryID = category.ID;
            product.xp.Category = category?.Name;
          }

          if (subcategory) {
            product.xp.SubCategoryID = subcategory.ID;
            product.xp.Category += ' > ' + subcategory?.Name;
          }

          setLoading(false);

          product = calculateStock(product, addressId);

          resolve(product);
          setLoading(false);
        })
        .catch((error) => {
          if (favorites) {
            resolve();
          } else {
            handleApiError(error);
            reject(error);
          }
          setLoading(false);
        });
    });
  };

  const GetProductsByAlternativePartNumber = (searchTerm, params) => {
    let url = `${EnvironmentConfig.apiMiddlewareUrl}products`;
    url = url + '/GetProductsByAlternativePartNumber/' + searchTerm;

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${Tokens.GetAccessToken()}` },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = res.Items;
          resolve(SetProductsData(data, params));
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
          setLoading(false);
        });
    });
  };

  const ListProducts = (params) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      Me.ListProducts(params)
        .then((productList) => {
          const data = productList.Items;
          setProductListLength(productList.Meta.TotalCount);
          resolve(SetProductsData(data, params));
        })
        .catch((error) => {
          handleApiError(error);
          reject(error);
          setLoading(false);
        });
    });
  };

  const SetProductsData = (data, params) => {
    if (data && data.length > 0) {
      // refactor some data
      data.forEach((element) => {
        // handle translation products
        if (element && element?.xp.Translation && element?.xp.Translation[language]) {
          element.Translation = element?.xp.Translation[language];
        }

        setCategoryID(element?.xp.Category);
        setParentCategoryID(element?.xp.Category);

        // handle translation categories
        let category = categories.find((category) => category.ID === element?.xp.Category);
        let subcategory = categories.find((category) => category.ID === element?.xp.SubCategory);

        if (category) {
          element.xp.CategoryID = category.ID;
          // element?.xp.Category = category?.Name;
        }

        if (subcategory) {
          element.xp.SubCategoryID = category.ID;
          // for product list only sub
          element.xp.Category = subcategory?.Name;
        }

        // add thumbnail image
        if (element?.xp && element?.xp.Images && element?.xp.Images.length > 0) {
          element.thumbnail = element?.xp.Images[0]?.ThumbnailUrl;
          element.image = element?.xp.Images[0].Url;
        }

        // set price
        if (
          element.PriceSchedule &&
          element.PriceSchedule.PriceBreaks &&
          element.PriceSchedule.PriceBreaks.length > 0
        ) {
          element.price = element.PriceSchedule.PriceBreaks[0].Price;
          element.SalePrice = element.PriceSchedule.PriceBreaks[0].SalePrice;
        }
        element = calculateStock(element, params.addressId);
      });
    }
    setProducts(data);
    console.log('productList', data);
    setLoading(false);
    return data;
  };

  const modifyLineItem = (lineItem, qty) => {
    let lineId = lineItem.ID;
    let product = lineItem.Product;
    if (!loadingCart) {
      // if category is Industrial Belts
      // and quantity available is less than desired qty
      // shows an alert

      let prod = products.find((prod) => prod.ID === product.ID);
      if (
        qtyExceeded === false &&
        product?.xp.CategoryID === 'IND' &&
        qty > prod.Inventory.QuantityAvailable
      ) {
        setProduct(lineItem);
        setQty(qty);
        setQtyExceeded(true);
      } else {
        setQtyExceeded(false);
        setLoading(true);
        CartOrderCloud.PatchLineItem(lineId, { Quantity: qty })
          .then((res) => {
            console.log('modifyLineItem');
            console.log(res);
            getCart(language);
          })
          .catch((error) => {
            handleApiError(error);
            getCart(language);
          });
      }
    }
  };

  const qtyExceededDialogFooter = (
    <React.Fragment>
      <Button
        label={t('No')}
        icon="pi pi-times"
        className="p-button-text bg-red-500 textWhite"
        onClick={() => {
          if (location.href.indexOf('cart') !== -1) {
            setQtyExceeded(false);
            getCart(language);
          } else setQtyExceeded(false);
        }}
      />
      <Button
        label={t('Yes')}
        icon="pi pi-check"
        className="p-button-text bg-green-500 textWhite"
        onClick={() => {
          if (location.href.indexOf('cart') !== -1) {
            modifyLineItem(product, qty);
          } else addToCart(product, qty);
        }}
      />
    </React.Fragment>
  );

  const confirmDuplicateOrderDialogFooter = (
    <React.Fragment>
      <Button
        label={t('No')}
        icon="pi pi-times"
        className="p-button-text bg-red-500 textWhite"
        onClick={() => setConfirmDuplicateOrder(false)}
      />
      <Button
        label={t('Yes')}
        icon="pi pi-check"
        className="p-button-text bg-green-500 textWhite"
        onClick={() => {
          setConfirmDuplicateOrder(false);
          duplicateOrder(orderToDuplicate);
        }}
      />
    </React.Fragment>
  );

  const qtyExceededDialog = () => {
    return (
      <Dialog
        visible={qtyExceeded}
        style={{ width: '450px' }}
        header={t('Confirm')}
        modal
        footer={() => qtyExceededDialogFooter}
        onHide={() => setQtyExceeded(false)}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>{t('qtyExceeded')} ?</span>
        </div>
      </Dialog>
    );
  };

  const confirmDuplicateOrderDialog = () => {
    return (
      <Dialog
        visible={confirmDuplicateOrder}
        style={{ width: '450px' }}
        header={t('Confirm')}
        modal
        footer={() => confirmDuplicateOrderDialogFooter}
        onHide={() => setConfirmDuplicateOrder(false)}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>
            {t('confirmDuplication')} {customerPO} ?
          </span>
        </div>
      </Dialog>
    );
  };

  const addToCart = (product, qty) => {
    if (!loadingCart) {
      // if category is Industrial Belts
      // and quantity available is less than desired qty
      // shows an alert
      if (
        qtyExceeded === false &&
        product?.xp.CategoryID === 'IND' &&
        qty > product.Inventory.QuantityAvailable
      ) {
        setProduct(product);
        setQty(qty);
        setQtyExceeded(true);
      } else {
        const productId = product.ID;
        setQtyExceeded(false);
        // check se esiste un ordine, altrimenti lo creo
        if (qty && productId) {
          const newLineItem = {
            ProductID: productId,
            Quantity: qty,
          };

          console.log('orderId', orderId);

          if (orderId) {
            addLineItem(newLineItem);
          } else {
            // create first a new order
            createOrder()
              .then((res) => {
                addLineItem(newLineItem);
              })
              .catch(() => {
                setLoading(false);
              });
          }
        } else {
          // window.alert('no quantity');
        }
      }
    }
  };

  // check if the one from sitecore is === to the one in ordercloud
  const checkLanguage = (languageID) => {
    // if not logged in
    if (!languageID) {
      // get from url
      const urlSplitted = location.pathname.split('/');
      let languageID = urlSplitted[1].replace(/\//g, '');

      if (location.pathname === '/') {
        if (Cookies.get('languageID')) {
          location.assign('/' + Cookies.get('languageID') + '/');
        } else {
          languageID = 'en';
          Cookies.set('languageID', languageID, { expires: 365 });
          location.assign('/' + languageID + '/');
        }
      }

      if (!languageID || languageID === 'undefined' || typeof languageID === 'undefined') {
        languageID = 'en';
      }
      Cookies.set('languageID', languageID, { expires: 365 });
    } else {
      if (location.pathname === '/') {
        if (Cookies.get('languageID')) {
          location.assign('/' + Cookies.get('languageID') + '/');
        } else {
          Cookies.set('languageID', languageID, { expires: 365 });
          location.assign('/' + languageID + '/');
        }
      }

      if (languageID !== Cookies.get('languageID')) {
        Cookies.set('languageID', languageID, { expires: 365 });
        location.assign('/' + languageID + '/');
      }
    }
  };

  const changeLanguage = (languageID) => {
    const oldLang = Cookies.get('languageID');
    const newUrl = window.location.href.replace('/' + oldLang + '/', '/' + languageID + '/');
    Cookies.set('languageID', languageID, { expires: 365 });
    setLoading(true);
    if (isLoggedIn) {
      Me.Patch({
        xp: {
          Language: languageID,
        },
      })
        .then((res) => {
          console.log('Me.Patch', res);
          setMe(res);
          setLoading(false);
          location.assign(newUrl);
        })
        .catch((error) => {
          handleApiError(error);
        });
    } else {
      // change URL with selected value
      location.assign(newUrl);
    }
  };

  const addToFavorites = (productID) => {
    setLoading(true);
    let FavoriteProducts = [];
    if (me?.xp.FavoriteProducts) {
      if (!isFavorite(productID)) {
        FavoriteProducts = me?.xp.FavoriteProducts;
        FavoriteProducts.push(productID);
      } else {
        FavoriteProducts = me?.xp.FavoriteProducts.filter(function (value, index, arr) {
          return value !== productID;
        });
      }
    }
    Me.Patch({
      xp: {
        FavoriteProducts: FavoriteProducts,
      },
    })
      .then((res) => {
        console.log('Me.Patch', res);
        setMe(res);
        setFavorites(res?.xp.FavoriteProducts);
        setLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const updateShippingAddress = (id) => {
    setCalculatedOrder(null);
    activeCart.ShippingAddressID = id;
    CartOrderCloud.Patch({ ShippingAddressID: id })
      .then((res) => {
        // setActiveCart(res);
        console.log('res.ShippingAddressID', res.ShippingAddressID, id);
        setSelectedShippingAddress(
          addresses.find((element) => element.ID === res.ShippingAddressID)
        );
        /* if (location.pathname === '/' + Cookies.get('languageID') + '/checkout') {
          calculateOrder();
        }*/
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const setActiveUser = (id, value, currentPage) => {
    setLoading(true);
    activeCart.ShippingAddressID = id;
    Users.Patch(me.Buyer.ID, id, { Active: value })
      .then((res) => {
        console.log('Users.Patch', res);
        setLoading(false);
        listUsers(currentPage);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const updateBillingAddress = (id) => {
    setLoading(true);
    activeCart.BillingAddressID = id;
    CartOrderCloud.Patch({ order: activeCart })
      .then((res) => {
        if (isDev) {
          console.log('res.BillingAddressID', res.BillingAddressID, id);
        }
        setSelectedBillingAddressId(res.BillingAddressID);
        setLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const isFavorite = (productId) => {
    if (!favorites) return false;
    const index = favorites.find((element) => element === productId);
    return index ? true : false;
  };

  const logoutHandler = () => {
    setLoading(true);
    Cookies.remove('authtoken');
    Tokens.RemoveAccessToken();
    Cookies.remove('isSalesAdmin');
    setMe(null);

    if (Cookies.get('impersonate')) {
      Tokens.SetAccessToken(Cookies.get('impersonateToken'));
      setUserAuthToken(Cookies.get('impersonateToken'));
      setIsLoggedIn(true);
      Cookies.set('authtoken', Cookies.get('impersonateToken'));

      Cookies.remove('impersonateToken');
      Cookies.remove('impersonate');
      setLoading(false);
      goToHome();
    } else {
      setUserAuthToken(null);
      Tokens.RemoveAccessToken();
      setLoading(false);
      goToHome();
      // location.assign('/en/');
    }
  };

  const afterLoginWorkflow = () => {
    // GET ME;
    getMe().then((me) => {
      const language = me?.xp.Language;

      setCustomerCode(me?.xp.CustomerCode);

      // GET CATALOG only if not seller
      if (me && me.Buyer && me.Buyer.DefaultCatalogID) {
        getCatalog(me).then((catalog) => {
          getUserGroups(catalog).then(() => {
            getCategories(language).then((categories) => {
              getCart(language, categories);
            });
          });
        });
      }
    });
  };
  const loginHandler = (username, password, isChecked, clientID) => {
    setLoading(true);
    const scope = AppConfig.scope;

    Cookies.remove('authtoken');
    Tokens.RemoveAccessToken();

    Auth.Login(username, password, clientID, scope)
      .then((response) => {
        const token = response.access_token;

        Tokens.SetAccessToken(token);
        setUserAuthToken(token);
        setIsLoggedIn(true);

        Cookies.set('authtoken', token);
        Cookies.remove('impersonate');

        // save info username and password
        if (isChecked) {
          Cookies.set('username', username);
          Cookies.set('password', password);
        } else {
          Cookies.remove('username');
          Cookies.remove('password');
        }

        afterLoginWorkflow();
      })
      .catch((error) => {
        if (clientID !== EnvironmentConfig.sellerClientId) {
          loginHandler(username, password, isChecked, EnvironmentConfig.sellerClientId);
        } else handleApiError(error);
      });
  };
  const handleApiError = (error) => {
    if (error.isOrderCloudError) {
      // the request was made and the API responded with a status code
      // that falls outside of the range of 2xx, the error will be of type OrderCloudError
      // https://ordercloud-api.github.io/ordercloud-javascript-sdk/classes/orderclouderror
      if (error.errors.Errors[0].ErrorCode === 'Authorization.InvalidToken') {
        showConfirmError(error.errors.Errors[0].Message);
      } else if (
        error.errors.Errors[0].Data &&
        error.errors.Errors[0].Data.body === 'DuplicatedPO'
      ) {
        showError(t('DuplicatedPO'), error.request.responseURL);
      } else {
        showError(error.errors.Errors[0].Message, error.request.responseURL);
      }
    } else if (error.request) {
      // the request was made but no response received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js

      showError(error.request, error.request.responseURL);
    } else {
      // Something happened in setting up the request that triggered an Error

      showError(error.message, error);
    }
  };
  const showSuccess = (message) => {
    toast.current.show({
      severity: 'success',
      summary: t('SuccessMessage'),
      detail: message,
      life: 3000,
    });
  };

  const showInfo = () => {
    toast.current.show({
      severity: 'info',
      summary: t('InfoMessage'),
      detail: 'Message Detail',
      life: 3000,
    });
  };

  const showWarn = () => {
    toast.current.show({
      severity: 'warn',
      summary: t('Warning'),
      detail: 'Message Detail',
      life: 3000,
    });
  };

  const showConfirmError = (error) => {
    if (toast && toast.current) {
      toast.current.remove();
    }

    toast.current.show({
      severity: 'error',
      sticky: true,
      summary: t('Error'),
      content: (
        <div className="flex flex-column" style={{ flex: '1' }}>
          <div className="text-center">
            <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
            <h4>{error}</h4>
            <p>{t('LoggedOut')}</p>
          </div>
          <div className="grid p-fluid">
            <div className="col-6 col-offset-3 mt-3 text-center">
              <Button
                type="button"
                label="OK"
                className="p-button-success"
                onClick={() => {
                  logoutHandler();
                  toast.current.clear();
                }}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const showError = (error, context) => {
    toast.current.show({
      severity: 'error',
      // summary: context ? 'Error Message in ' + context : 'Error',
      summary: t('Error'),
      detail: error,
      life: 5000,
    });
    setLoading(false);
  };

  return (
    <CommonApi.Provider
      value={{
        blocked: blocked,
        loading: loading,
        loadingUpload: loadingUpload,
        valueUpload: valueUpload,
        loadingCart: loadingCart,
        isLoggedIn: isLoggedIn,
        favorites: favorites,
        authToken: userAuthToken,
        newPassword: newPassword,
        me: me,
        language: language,
        catalog: catalog,
        categories: categories,
        groups: groups,
        orders: orders,
        totalOrders: totalOrders,
        customerCareRelationship: customerCareRelationship,
        customerCode: customerCode,
        toast: useRef(),
        orderId: orderId,
        Total: Total,
        LineItemCount: LineItemCount,
        calculatedOrder: calculatedOrder,
        quantities: quantities,
        lineItems: lineItems,
        products: products,
        addresses: addresses,
        activeCart: activeCart,
        selectedShippingAddress: selectedShippingAddress,
        selectedBillingAddressId: selectedBillingAddressId,
        totalProductsInCart: totalProductsInCart,
        currencySymbol: currencySymbol,
        formatCategoriesMenu: formatCategoriesMenu,
        productListLength: productListLength,
        users: users,
        categoryID: categoryID,
        parentCategoryID: parentCategoryID,
        showStock: showStock,
        addressId: addressId,
        isSeller: isSeller,
        errorsUpload: errorsUpload,
        locale: locale,
        dateFormat: dateFormat,
        isimpersonating: isimpersonating,
        title: title,
        connection: connection,
        expandedRows: expandedRows,
        allexpandedRows: allexpandedRows,
        confirmDuplicateOrder: confirmDuplicateOrder,
        ordersexcel: ordersexcel,
        startSignalR: startSignalR,
        getTranslationCategory: getTranslationCategory,
        updateUser: updateUser,
        deleteUser: deleteUser,
        createUser: createUser,
        listUsers: listUsers,
        verifyCode: verifyCode,
        createOrder: createOrder,
        addToCart: addToCart,
        duplicateOrder: duplicateOrder,
        GetProductsByAlternativePartNumber: GetProductsByAlternativePartNumber,
        getCart: getCart,
        deleteCart: deleteCart,
        modifyLineItem: modifyLineItem,
        removeFromCart: removeFromCart,
        sendOrder: sendOrder,
        saveNewPassword: saveNewPassword,
        sendVerificationCode: sendVerificationCode,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        handleApiError: handleApiError,
        showSuccess: showSuccess,
        showWarn: showWarn,
        showError: showError,
        isFavorite: isFavorite,
        addToFavorites: addToFavorites,
        listAddresses: listAddresses,
        calculateOrder: calculateOrder,
        selectShippingAddress: selectShippingAddress,
        selectBillingAddress: selectBillingAddress,
        setActiveUser: setActiveUser,
        ListProducts: ListProducts,
        GetProduct: GetProduct,
        downloadTemplate: downloadTemplate,
        changeLanguage: changeLanguage,
        getLineItemsFromOrder: getLineItemsFromOrder,
        getLineItemsFromOrderExcel: getLineItemsFromOrderExcel,
        listOrders: listOrders,
        listOrdersExcel: listOrdersExcel,
        listAllUsers: listAllUsers,
        impersonateUser: impersonateUser,
        setBlocked: setBlocked,
        setLoading: setLoading,
        setValueUpload: setValueUpload,
        setLoadingUpload: setLoadingUpload,
        setLoadingCart: setLoadingCart,
        setErrorsUpload: setErrorsUpload,
        qtyExceededDialog: qtyExceededDialog,
        confirmDuplicateOrderDialog: confirmDuplicateOrderDialog,
        setConfirmDuplicateOrder: setConfirmDuplicateOrder,
        setOrderToDuplicate: setOrderToDuplicate,
        setCustomerPO: setCustomerPO,
        setUserViewAllOrder: setUserViewAllOrder,
      }}
    >
      <Toast ref={toast} position="top-center" />
      <div className="overlay" hidden={!loading}>
        <ProgressSpinner />
      </div>
      <BlockUI blocked={loading} fullScreen>
        {props.children}
      </BlockUI>
    </CommonApi.Provider>
  );
};

export default CommonApi;
