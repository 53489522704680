import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from 'primereact/multiselect';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import CommonApi from '../CommonApi';
import Breadcrumbs from '../Breadcrumbs';
import { formatDate, formatPrice, goToProduct } from '../Helper';
import { useTranslation } from 'react-i18next';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { BlockUI } from 'primereact/blockui';
import Cookies from 'js-cookie';
import { Paginator } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { ScrollTop } from 'primereact/scrolltop';

const MyOrders = (props) => {
  const op = useRef(null);
  const commonCtx = useContext(CommonApi);
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [localFilterValue, setLocalFilterValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [filtersOverlay, setFiltersOverlay] = useState(null);
  const [localFilters, setLocalFilters] = useState(null);
  const { t } = useTranslation();
  const [statuses] = useState(['Open', 'Completed', 'AwaitingApproval', 'Canceled']);

  const [statusesOverlay] = useState([
    'ReadyToBeShipped',
    'InProgress',
    'Complete',
    'New',
    'Canceled',
  ]);
  const [blocked, setBlocked] = useState(false);
  const [expandedOrder, setExpandedOrder] = useState('');
  const [selectedOrder, setSelectedOrder] = useState('');
  const [selectedID, setSelectedID] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedErpID, setSelectedErpID] = useState('');
  const [pageInputTooltip, setPageInputTooltip] = useState('Press  Enter  key to go to this page.');
  const [pageInputTooltipOrders, setPageInputTooltipOrders] = useState(
    'Press  Enter  key to go to this page.'
  );
  const [valueSearch, setValueSearch] = useState(null);
  const [valueTypeSearch, setValueTypeSearch] = useState('CustomerPO');

  const [first, setFirst] = useState(0);
  const [firstOrders, setFirstOrders] = useState(0);
  const [rowsOrders, setRowsOrders] = useState(100);
  const [rows, setRows] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageOrders, setCurrentPageOrders] = useState(1);

  const selectItems = [
    { label: t('CustomerPO'), value: 'CustomerPO' },
    { label: t('ErpID'), value: 'ErpID' },
  ];

  const expandOrder = (rowData) => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';

    console.log(rowData);

    // commonCtx.setExpandedRows(null);
    setExpandedOrder(rowData);
    setSelectedOrder(rowData?.xp.CustomerPO ? rowData?.xp.CustomerPO : 'N/A');
    setSelectedID(rowData.ID ? rowData.ID : 'N/A');
    setSelectedUser(
      rowData.FromUser && rowData.FromUser.Username ? rowData.FromUser.Username : 'N/A'
    );
    setSelectedErpID(rowData?.xp.ErpID ? rowData?.xp.ErpID : 'N/A');

    setLocalFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Product.Name': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'xp.Status': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setCurrentPage(1);
    setFirst(0);
    setRows(100);
    commonCtx.getLineItemsFromOrder(rowData.ID, 1);
  };

  const duplicateOrder = (rowData) => {
    commonCtx.setCustomerPO(rowData?.xp.CustomerPO);
    commonCtx.setOrderToDuplicate(rowData.ID);
    commonCtx.setConfirmDuplicateOrder(true);
  };

  const goToPrev = (e) => {
    commonCtx.getLineItemsFromOrder(selectedID, currentPage - 1, localFilters);
    setCurrentPage(currentPage - 1);
    setFirst(first - rows);
  };

  const goToNext = (e) => {
    commonCtx.getLineItemsFromOrder(selectedID, currentPage + 1, localFilters);
    setCurrentPage(currentPage + 1);
    setFirst(first + rows);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    commonCtx.getLineItemsFromOrder(selectedID, event.page + 1, localFilters);
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(event.currentTarget.value);

      if (page < 0 || page > options.totalPages) {
        setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
        return false;
      } else {
        const first = currentPage ? rows * (page - 1) : 0;
        setFirst(first);
        setPageInputTooltip('Enter number to go to this page.');
      }

      commonCtx.getLineItemsFromOrder(selectedID, event.currentTarget.value, localFilters);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    }
  };

  const goToPrevOrders = (e) => {
    commonCtx.listOrders(currentPageOrders - 1, 100, filters);
    setCurrentPageOrders(currentPageOrders - 1);
    setFirstOrders(firstOrders - rows);
  };

  const goToNextOrders = (e) => {
    commonCtx.listOrders(currentPageOrders + 1, 100, filters);
    setCurrentPageOrders(currentPageOrders + 1);
    setFirstOrders(firstOrders + rows);
  };

  const onPageChangeOrders = (event) => {
    setFirstOrders(event.first);
    commonCtx.listOrders(event.page + 1, 100, filters);
    setCurrentPageOrders(event.page + 1);
  };

  const onPageInputKeyDownOrders = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(event.currentTarget.value);

      if (page < 0 || page > options.totalPages) {
        setPageInputTooltipOrders(`Value must be between 1 and ${options.totalPages}.`);
        return false;
      } else {
        const first = currentPageOrders ? rows * (page - 1) : 0;
        setFirstOrders(firstOrders);
        setPageInputTooltipOrders('Enter number to go to this page.');
      }

      commonCtx.listOrders(event.currentTarget.value, 100, filters);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    }
  };

  const template1 = {
    layout: ' CurrentPageReport PrevPageLink PageLinks  NextPageLink   RowsPerPageDropdown',
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },

    PrevPageLink: (options) => {
      return (
        <>
          <button
            type="button"
            className={options.className}
            onClick={() => goToPrev(options)}
            disabled={options.disabled}
          >
            <span className="p-3">{t('Previous')}</span>
            <Ripple />
          </button>
        </>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={() => goToNext(options)}
          disabled={options.disabled}
        >
          <span className="p-3">{t('Next')}</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span className={className} style={{ userSelect: 'none' }}>
            ...
          </span>
        );
      }

      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      return (
        <>
          <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            {t('GoToPage')}
            <InputNumber
              size="2"
              className="ml-1"
              tooltip={pageInputTooltip}
              onKeyDown={(e) => onPageInputKeyDown(e, options)}
            />{' '}
            of {options.totalPages}
          </span>
        </>
      );
    },
  };

  const template2 = {
    layout: ' CurrentPageReport PrevPageLink PageLinks  NextPageLink   RowsPerPageDropdown',
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },

    PrevPageLink: (options) => {
      return (
        <>
          <button
            type="button"
            className={options.className}
            onClick={() => goToPrevOrders(options)}
            disabled={options.disabled}
          >
            <span className="p-3">{t('Previous')}</span>
            <Ripple />
          </button>
        </>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={() => goToNextOrders(options)}
          disabled={options.disabled}
        >
          <span className="p-3">{t('Next')}</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span className={className} style={{ userSelect: 'none' }}>
            ...
          </span>
        );
      }

      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      return (
        <>
          <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            {t('GoToPage')}
            <InputNumber
              size="2"
              className="ml-1"
              tooltip={pageInputTooltipOrders}
              onKeyDown={(e) => onPageInputKeyDownOrders(e, options)}
            />{' '}
            of {options.totalPages}
          </span>
        </>
      );
    },
  };

  useEffect(() => {
    document.title = commonCtx.title + ` - ${t('MyOrders')}`;
    if (!commonCtx.loading) {
      if (!commonCtx.isLoggedIn) {
        location.assign('/' + Cookies.get('languageID') + '/');
      } else {
        if (loading) {
          const status = new URLSearchParams(window.location.search).get('status')
            ? new URLSearchParams(window.location.search).get('status')
            : null;

          initFilters(status);

          commonCtx.listOrders(currentPageOrders, 100, {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            Status: {
              operator: FilterOperator.OR,
              constraints: [{ value: status, matchMode: FilterMatchMode.EQUALS }],
            },
          });
          commonCtx.listAddresses();
          setLoading(false);
        }
      }
    }
  });

  const actionBodyTemplate = (rowData) => {
    /*

        <Button
          hidden="true"
          icon="pi pi-search pointer"
          label="Tracking"
          className="p-button-rounded p-button-info pointer"
          onClick={() => commonCtx.trackShipping(rowData)}
        />

*/

    return (
      <React.Fragment>
        <Button
          icon="pi pi-copy"
          tooltip={t('DuplicateOrder')}
          tooltipOptions={{ position: 'top' }}
          hidden={Cookies.get('isSalesAdmin') ? true : false}
          className="p-button-rounded p-button-success mr-2"
          onClick={() => duplicateOrder(rowData)}
        />
      </React.Fragment>
    );
  };

  const getStatus = (status) => {
    switch (status) {
      case 'ReadyToBeShipped':
        return ' bg-orange-500 textWhite';
      case 'InProgress':
        return ' bg-blue-500 textWhite';
      case 'Complete':
        return ' bg-green-500 textWhite';
      case 'New':
        return ' bg-red-500 textWhite';
      case 'Canceled':
        return ' bg-gray-500 textWhite';
      default:
        return ' bg-white';
    }
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'Open':
        return ' bg-orange-500 textWhite';
      case 'Completed':
        return ' bg-green-500 textWhite';
      case 'AwaitingApproval':
        return ' bg-gray-500 textWhite';
      case 'Canceled':
        return ' bg-red-500 textWhite';
      default:
        return ' bg-white';
    }
  };

  const imageBodyTemplate = (rowData) => {
    rowData = rowData.Product;
    return (
      <Image
        preview
        width="80"
        src={`${rowData?.xp?.Images[0]?.ThumbnailUrl}`}
        onError={(e) => (e.target.src = '/nophoto.jpg')}
        alt={`${rowData?.xp?.Images[0]?.ThumbnailUrl}`}
        className="imageThumbInCart"
      />
    );
  };

  const dateTemplate = (rowData) => {
    return formatDate(rowData.DateSubmitted, false, commonCtx.locale);
  };

  const dateTemplateStatus = (rowData) => {
    if (rowData?.xp && rowData?.xp.LastUpdate) {
      return formatDate(rowData?.xp?.LastUpdate, false, commonCtx.locale);
    } else return '';
  };

  const dateTemplateExpected = (rowData) => {
    if (rowData?.xp && rowData?.xp.ExpectedShippedDate) {
      return formatDate(rowData?.xp?.ExpectedShippedDate, false, commonCtx.locale);
    } else return '';
  };

  const dateDeliveryTemplate = (rowData) => {
    return formatDate(rowData?.xp?.RequestedDeliveryDate, false, commonCtx.locale);
  };

  const priceTemplateSubtotal = (rowData) => {
    return formatPrice(rowData.Subtotal, commonCtx.locale);
  };

  const addressTemplate = (rowData) => {
    const address = commonCtx.addresses.find((element) => element.ID === rowData.ShippingAddressID);
    if (address) {
      return (
        '' +
        (address.AddressName ? address.AddressName : '') +
        ' - ' +
        (address.Street1 ? address.Street1 : '') +
        ', ' +
        (address.City ? address.City : '') +
        ' ' +
        (address.Zip ? address.Zip : '') +
        ', ' +
        (address.Country ? address.Country : '')
      );
    }
    return '';
  };

  const priceTemplateUnitPrice = (rowData) => {
    return formatPrice(rowData.UnitPrice, commonCtx.locale);
  };

  const priceTemplateLineTotal = (rowData) => {
    return formatPrice(rowData.LineTotal, commonCtx.locale);
  };

  const clickTemplate = (rowData) => {
    return (
      <div
        className="text-xl font-bold pointer blueText hoverBlack"
        onClick={() => goToProduct(rowData.Product.ID)}
      >
        {rowData.Product.Name}
      </div>
    );
  };

  const expandTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-search"
        tooltip={t('SeeDetails')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-rounded p-button-info mr-2"
        onClick={(e) => {
          op.current.toggle(e);
          expandOrder(rowData);
        }}
      />
    );
  };
  const statusBodyOverlayTemplate = (rowData) => {
    if (rowData?.xp && rowData?.xp.Status) {
      return (
        <Tag
          value={t(rowData?.xp.Status)}
          severity={getStatus(rowData?.xp.Status)}
          className={'product-badge status-instock textWhite'}
        />
      );
    } else return '';
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={t(rowData.Status)}
        severity={getSeverity(rowData.Status)}
        className={'product-badge status-instock textWhite'}
      />
    );
  };

  const clearItems = (options) => {
    setLocalFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Product.Name': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'xp.Status': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    commonCtx.getLineItemsFromOrder(selectedID, currentPage, {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Product.Name': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'xp.Status': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
  };

  const clearOrders = (options) => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'xp.CustomerPO': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'xp.ErpID': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      Status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    commonCtx.listOrders(currentPageOrders, 100, {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
  };

  const statusOverlayFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statusesOverlay}
        onChange={(e) => {
          setLocalFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            'Product.Name': {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
            },
            'xp.Status': {
              operator: FilterOperator.AND,
              constraints: [{ value: e.value, matchMode: FilterMatchMode.STARTS_WITH }],
            },
          });
          commonCtx.getLineItemsFromOrder(selectedID, currentPage, {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            'Product.Name': {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
            },
            'xp.Status': {
              operator: FilterOperator.AND,
              constraints: [{ value: e.value, matchMode: FilterMatchMode.STARTS_WITH }],
            },
          });
        }}
        valueTemplate={statusItemOverlayTemplate}
        itemTemplate={statusItemOverlayTemplate}
        placeholder={t('SelectOne')}
        className="p-column-filter"
      />
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => {
          // options.filterApplyCallback(e.value);
          setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            'xp.CustomerPO': {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
            },
            'xp.ErpID': {
              operator: FilterOperator.AND,
              constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
            },
            Status: {
              operator: FilterOperator.OR,
              constraints: [{ value: e.value, matchMode: FilterMatchMode.EQUALS }],
            },
          });
          commonCtx.listOrders(currentPageOrders, 100, {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            Status: {
              operator: FilterOperator.OR,
              constraints: [{ value: e.value, matchMode: FilterMatchMode.EQUALS }],
            },
          });
        }}
        valueTemplate={statusItemTemplate}
        itemTemplate={statusItemTemplate}
        placeholder={t('SelectOne')}
        className="p-column-filter"
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <Tag value={t(option)} severity={getSeverity(option)} />;
  };

  const statusItemOverlayTemplate = (option) => {
    return <Tag value={t(option)} severity={getStatus(option)} />;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchByKeyword();
    }
  };

  const searchByKeyword = (value) => {
    let typeSearch = 'xp.' + valueTypeSearch;
    if (valueTypeSearch === 'CustomerPO') {
      commonCtx.listOrders(1, value === 'clear' ? 100 : 100, {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'xp.CustomerPO': {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: value === 'clear' ? '' : valueSearch + '*',
              matchMode: FilterMatchMode.STARTS_WITH,
            },
          ],
        },
      });
    } else {
      commonCtx.listOrders(1, value === 'clear' ? 100 : 100, {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'xp.ErpID': {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: value === 'clear' ? '' : valueSearch + '*',
              matchMode: FilterMatchMode.STARTS_WITH,
            },
          ],
        },
      });
    }
  };

  const onSearchLocalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters.global.value = value;

    setLocalFilters(_filters);
    setLocalFilterValue(value);
  };

  const initFilters = (status) => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'xp.CustomerPO': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'xp.ErpID': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      Status: {
        operator: FilterOperator.OR,
        constraints: [{ value: status ? status : '', matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setLocalFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Product.Name': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      'xp.Status': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
  };

  const handleOverlayShow = () => {
    setBlocked(true);
  };

  const handleOverlayHide = () => {
    document.body.style.overflow = 'visible';
    document.body.style.position = 'relative';
    setBlocked(false);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-right">
          <Dropdown
            value={valueTypeSearch}
            options={selectItems}
            onChange={(e) => setValueTypeSearch(e.value)}
          />

          <InputText
            placeholder={t('KeywordSearch')}
            value={valueSearch ? valueSearch : ''}
            className="ml-2"
            onKeyDown={handleKeyPress}
            onChange={(e) => setValueSearch(e.target.value)}
          />
          <Button
            type="button"
            className="ml-2"
            icon="pi pi-search"
            label={t('Search')}
            onClick={() => searchByKeyword()}
          />
          <Button
            type="button"
            className="ml-2"
            icon="pi pi-filter-slash"
            label={t('Clear')}
            onClick={() => {
              searchByKeyword('clear');
            }}
          />
        </span>

        <span className="p-input-icon-left">
          {commonCtx.orders?.length !== 0 && (
            <Button
              type="button"
              label={t('Download')}
              icon="pi pi-file-excel"
              onClick={() => exportExcel()}
              className="p-button-success mr-2"
              data-pr-tooltip="XLS"
            />
          )}
        </span>
      </div>
    );
  };

  const renderHeaderOverlay = () => {
    console.log(expandedOrder);
    return (
      <div className="flex-row zindex301">
        <div className="flex  justify-content-between">
          <span style={{ width: '20%' }}>
            {t('CustomerPO')} : <b>{selectedOrder}</b>
          </span>
          <span style={{ width: '20%' }}>
            {t('ErpID')} : <b>{selectedErpID}</b>
          </span>
          <span style={{ width: '20%' }}>
            {t('ID')} : <b>{selectedID}</b>
          </span>
          <span style={{ width: '20%', textAlign: 'right' }}>
            {t('OrderCreatedBy')} : <b>{selectedUser}</b>
          </span>
          <span style={{ width: '20%', textAlign: 'right' }}>
            {commonCtx.expandedRows?.length > 0 && (
              <Button
                type="button"
                icon="pi pi-file-excel"
                label={t('Download')}
                onClick={() => exportExcelOverlay()}
                className="p-button-success mr-2"
                data-pr-tooltip="XLS"
              />
            )}
          </span>
        </div>

        {expandedOrder && (
          <div className="flex  justify-content-between">
            <span style={{ width: '20%' }}>
              {t('OrderTotal')} : <b>{formatPrice(expandedOrder.Total, commonCtx.locale)}</b>
            </span>
            <span style={{ width: '20%' }}>
              {t('ProductsTotal')} : <b>{formatPrice(expandedOrder.Subtotal, commonCtx.locale)}</b>
            </span>
            <span style={{ width: '20%' }}>
              {t('ShippingCost')} :{' '}
              <b>
                {expandedOrder.ShippingCost === 0
                  ? new Intl.NumberFormat(commonCtx.locale.Language, {
                      style: 'currency',
                      currency: commonCtx.locale.Currency,
                    })
                      .format(0)
                      // if the price begins with digit, place the space after the digit
                      .replace(/^([\d,.]+)/, '$1 ')
                      // if the price ends with digit, place the space before the digit
                      .replace(/([\d,.]+)$/, ' $1')
                  : formatPrice(expandedOrder.ShippingCost, commonCtx.locale)}
              </b>
            </span>
            <span style={{ width: '20%' }}>
              {expandedOrder.TaxCost > 0 && (
                <>
                  <span>{t('TaxCost')} : </span>

                  {expandedOrder.TaxCost > 0 && <b></b>}
                </>
              )}
            </span>
            <span style={{ width: '20%' }}> &nbsp; </span>
          </div>
        )}
      </div>
    );
  };

  const header = renderHeader();
  const headerOverlay = renderHeaderOverlay();

  const exportExcel = () => {
    commonCtx.listOrdersExcel().then((allorders) => {
      import('xlsx').then((xlsx) => {
        let orders = [];
        for (let index = 0; index < allorders.length; index++) {
          orders[index] = {};
          orders[index][t('CustomerPO')] = allorders[index].xp.CustomerPO;
          orders[index][t('ErpID')] = allorders[index].xp.ErpID;
          orders[index][t('LineItems')] = allorders[index].LineItemCount;
          orders[index][t('Status')] = t(allorders[index].Status);
          orders[index][t('DateSubmitted')] = allorders[index].DateSubmitted
            ? formatDate(allorders[index].DateSubmitted, false, commonCtx.locale)
            : '';
          orders[index][t('DateApproved')] = allorders[index].DateApproved
            ? formatDate(allorders[index].DateApproved, false, commonCtx.locale)
            : '';
          orders[index][t('DateCanceled')] = allorders[index].DateCanceled
            ? formatDate(allorders[index].DateCanceled, false, commonCtx.locale)
            : '';
          orders[index][t('DateCompleted')] = allorders[index].DateCompleted
            ? formatDate(allorders[index].DateCompleted, false, commonCtx.locale)
            : '';
          /* orders[index][t('ExpectedShippedDate')] = allorders[index].xp.ExpectedShippedDate
            ? formatDate(allorders[index].xp.ExpectedShippedDate, false, commonCtx.locale)
            : ''; */
          orders[index][t('LastUpdateStatus')] = allorders[index].LastUpdated
            ? formatDate(allorders[index].LastUpdated, false, commonCtx.locale)
            : '';
          // orders[index][t('Subtotal')] = commonCtx.orders[index].Subtotal;
          orders[index][t('Total')] = allorders[index].Total;
          orders[index][t('Currency')] = allorders[index].Currency;

          const address = commonCtx.addresses.find(
            (element) => element.ID === allorders[index].ShippingAddressID
          );
          if (address) {
            orders[index][t('ShippingAddress')] =
              '' +
              (address.AddressName ? address.AddressName : '') +
              ' - ' +
              (address.Street1 ? address.Street1 : '') +
              ', ' +
              (address.City ? address.City : '') +
              ' ' +
              (address.Zip ? address.Zip : '') +
              ', ' +
              (address.Country ? address.Country : '');
          }
        }
        const worksheet = xlsx.utils.json_to_sheet(orders);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'MyOrders');
      });
    });
  };

  const exportExcelOverlay = () => {
    commonCtx.getLineItemsFromOrderExcel(selectedID).then((allexpandedRows) => {
      if (allexpandedRows && allexpandedRows.length > 0) {
        import('xlsx').then((xlsx) => {
          let orders = [];
          for (let index = 0; index < allexpandedRows.length; index++) {
            orders[index] = {};
            orders[index][t('CustomerPO')] = selectedOrder;
            orders[index][t('ErpID')] = selectedErpID;
            orders[index][t('PartNumber')] = allexpandedRows[index].Product.Name;
            orders[index][t('Quantity')] = allexpandedRows[index].Quantity;
            orders[index][t('QuantityShipped')] = allexpandedRows[index].QuantityShipped;
            orders[index][t('UnitPrice')] = allexpandedRows[index].UnitPrice;
            orders[index][t('Total Price')] = allexpandedRows[index].LineTotal;
            orders[index][t('Currency')] = commonCtx.orders[0].Currency;
            orders[index][t('Status')] = t(allexpandedRows[index].xp.Status);
            orders[index][t('ExpectedShippedDate')] = allexpandedRows[index].xp.ExpectedShippedDate
              ? formatDate(allexpandedRows[index].xp.ExpectedShippedDate, false, commonCtx.locale)
              : '';
            orders[index][t('LastUpdateStatus')] = allexpandedRows[index].xp.LastUpdate
              ? formatDate(allexpandedRows[index].xp.LastUpdate, false, commonCtx.locale)
              : '';
          }
          const worksheet = xlsx.utils.json_to_sheet(orders);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'MyOrder');
        });
      }
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  if (commonCtx.isLoggedIn) {
    const userInfo = commonCtx.me;
    return (
      <>
        {commonCtx.confirmDuplicateOrderDialog()}
        <div>
          <Breadcrumbs leaf={t('MyOrders')}></Breadcrumbs>
          <div className="col-12 surface-card shadow-2 border-round p-4">
            <div className="datatable-rowexpansion-demo">
              <DataTable
                value={commonCtx.orders}
                responsiveLayout="scroll"
                globalFilterFields={[
                  'ID',
                  'xp.CustomerPO',
                  'xp.ErpID',
                  'Status',
                  'ShippingAddressID',
                  'xp.Notes',
                ]}
                size="100"
                filters={filters}
                dataKey="ID"
                stripedRows
                header={header}
              >
                <Column field="ID" body={expandTemplate} />
                <Column field="xp.CustomerPO" header={t('CustomerPO')} sortable />
                <Column field="xp.ErpID" header={t('ErpID')} sortable />
                <Column
                  field="Status"
                  filterField="Status"
                  header={t('Status')}
                  style={{ minWidth: '12rem' }}
                  onFilterClear={clearOrders}
                  filter
                  body={statusBodyTemplate}
                  filterElement={statusFilterTemplate}
                />
                <Column field="LineItemCount" header={t('LineItems')} />
                <Column field="Subtotal" header={t('Subtotal')} hidden="true" />
                <Column field="TaxCost" header={t('TaxCost')} hidden="true" />
                <Column hidden="true" field="ShippingCost" header={t('Shipping Cost')} />
                <Column
                  field="Subtotal"
                  style={{ minWidth: '12rem' }}
                  body={priceTemplateSubtotal}
                  header={t('TotalPrice')}
                />
                <Column
                  field="DateSubmitted"
                  header={t('DateSubmitted')}
                  body={dateTemplate}
                  sortable
                />
                <Column
                  field="xp.RequestedDeliveryDate"
                  header={t('ExpectedDeliveryDate')}
                  body={dateDeliveryTemplate}
                  sortable
                />
                <Column
                  field="ShippingAddressID"
                  body={addressTemplate}
                  header={t('ShippingAddress')}
                  sortable
                />
                <Column field="xp.Notes" header={t('Notes')} sortable />
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  hidden={Cookies.get('isSalesAdmin') ? true : false}
                ></Column>
              </DataTable>
              {commonCtx.totalOrders > 100 && (
                <Paginator
                  size="100"
                  template={template2}
                  first={firstOrders}
                  rows={rowsOrders}
                  totalRecords={commonCtx.totalOrders}
                  onPageChange={onPageChangeOrders}
                ></Paginator>
              )}
              <ScrollTop />
              <BlockUI blocked={blocked || commonCtx.blocked} fullScreen className="blockOrders">
                <OverlayPanel
                  ref={op}
                  showCloseIcon
                  my="center top"
                  at="right center"
                  className=" fixed block top-50 left-50 my-orders-overlay-panel"
                  onShow={handleOverlayShow}
                  onHide={handleOverlayHide}
                >
                  <div className="orders-subtable zindex301">
                    <DataTable
                      header={headerOverlay}
                      value={commonCtx?.expandedRows}
                      globalFilterFields={[
                        'ID',
                        'xp.Status',
                        'Product.Name',
                        'Product.xp.InternalPartNumber',
                      ]}
                      filters={localFilters}
                      dataKey="ID"
                      id="detail"
                      size="100"
                      responsiveLayout="scroll"
                      stripedRows
                      scrollable={true}
                      scrollHeight="70vh"
                      style={{ width: '90vw' }}
                    >
                      <Column
                        field="Product.Name"
                        header={t('PartNumber')}
                        sortable
                        body={clickTemplate}
                      ></Column>
                      <Column
                        field="Product.xp.InternalPartNumber"
                        header={t('InternalPartNumber')}
                      ></Column>
                      <Column
                        field="UnitPrice"
                        body={priceTemplateUnitPrice}
                        header={t('UnitPrice')}
                      ></Column>
                      <Column field="Quantity" header={t('OrderQuantity')} sortable></Column>
                      <Column
                        field="xp.QuantityOnWorking"
                        header={t('QuantityOnWorking')}
                        sortable
                      ></Column>
                      <Column
                        field="QuantityShipped"
                        header={t('QuantityShipped')}
                        sortable
                      ></Column>
                      <Column
                        field="LineTotal"
                        body={priceTemplateLineTotal}
                        header={t('Total Price')}
                      />
                      <Column
                        field="xp.ExpectedShippedDate"
                        body={dateTemplateExpected}
                        header={t('ExpectedShippedDate')}
                        sortable
                      ></Column>
                      <Column
                        field="xp.Status"
                        style={{ minWidth: '12rem' }}
                        body={statusBodyOverlayTemplate}
                        header={t('Status')}
                        onFilterClear={clearItems}
                        filter
                        filterField="xp.Status"
                        filterElement={statusOverlayFilterTemplate}
                      />

                      <Column
                        field="xp.LastUpdate"
                        body={dateTemplateStatus}
                        sortable
                        header={t('LastUpdateStatus')}
                      />
                    </DataTable>
                    {commonCtx?.productListLength > 100 && (
                      <Paginator
                        template={template1}
                        first={first}
                        rows={rows}
                        totalRecords={commonCtx?.productListLength}
                        onPageChange={onPageChange}
                      ></Paginator>
                    )}
                  </div>
                </OverlayPanel>
              </BlockUI>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default MyOrders;
