import React, { useContext, useRef, useState, useEffect } from 'react';
import { StyleClass } from 'primereact/styleclass';
import { Ripple } from 'primereact/ripple';
import CommonApi from '../CommonApi';
import { goToCategory, goToFavorites, isDev } from '../Helper';
import { useTranslation } from 'react-i18next';
import { Tree } from 'primereact/tree';
import Cookies from 'js-cookie';
import returnHTML from 'react-prime/lib/returnHTML';

const CategoriesWidget = (props) => {
  const [loading, setLoading] = useState(true);
  const [nodes, setNodes] = useState([]);
  const btnRef = [];
  const commonCtx = useContext(CommonApi);
  const { t } = useTranslation();
  let categoryID = new URLSearchParams(window.location.search).get('categoryID')
    ? new URLSearchParams(window.location.search).get('categoryID')
    : null;

  if (categoryID && categoryID !== 'favorites') {
    categoryID = window.atob(categoryID);
  }

  useEffect(() => {
    // first format categories and subcategories

    if (!commonCtx.loading && loading) {
      setLoading(false);
      let categoriesLeft = [];
      if (commonCtx.isLoggedIn) {
        // create template for each category formatted

        function pushInsideFather(categories, id, node) {
          categories.forEach((element) => {
            if (element.key === id) {
              element.children.push(node);
            } else {
              if (element.children && element.children.length > 0) {
                pushInsideFather(element.children, id, node);
              }
            }
          });
        }

        function iterateChildren(categories, id) {
          categories.forEach((category) => {
            let node = {
              key: category.id,
              label: category.label,
              icon: category.icon,
              data: category.label,
              value: category.id,
              expanded: true,
              children: [],
            };

            if (id) {
              pushInsideFather(categoriesLeft, id, node);
            } else {
              categoriesLeft.push(node);
            }

            if (category.ChildCount > 0) {
              node.className = '';
              iterateChildren(category.items, category.id);
            } else {
              node.className = 'blueTextNode';
              if (categoryID && categoryID === category.id) {
                node.className += ' selectedNode';
              }
            }
          });
        }

        setNodes(categoriesLeft);
        iterateChildren(commonCtx.formatCategoriesMenu);
      }
    }
  });

  return (
    <div className="hidden xl:block xl:col-2 h-full  ml-0">
      <div className="surface-0 shadow-2  ml-0 mr-2 ">
        <div className="p-3 flex align-items-center text-900">
          <i className="pi pi-book mr-2"></i>
          <span className="font-medium">{t('Categories')}</span>
        </div>

        <Tree
          value={nodes}
          className="w-full"
          onNodeClick={(e) => {
            if (
              (e.node.children && e.node.children.length > 0) ||
              (categoryID && categoryID === e.node.key)
            ) {
              return;
            } else goToCategory(e.node.value);
          }}
        />
        {!Cookies.get('isSalesAdmin') && (
          <div
            className="p-3 flex align-items-center text-900 pointer"
            onClick={() => goToFavorites()}
          >
            {categoryID === 'favorites' && (
              <a className="p-ripple flex-1 cursor-pointer py-2 px-3 hover:surface-hover transition-colors transition-duration-150 text-700 font-medium line-height-3 text-primary">
                <i className="pi pi-heart mr-2"></i>
                <span className="font-medium">{t('Favorites')}</span>
              </a>
            )}
            {categoryID !== 'favorites' && (
              <>
                <i className="pi pi-heart mr-2"></i>
                <span className="font-medium">{t('Favorites')}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoriesWidget;
