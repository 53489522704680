import React, { useState, useContext, useEffect, useRef } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Buyers, SecurityProfiles, UserGroups, Users } from 'ordercloud-javascript-sdk';
import CommonApi from '../CommonApi';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Breadcrumbs from '../Breadcrumbs';
import { ToggleButton } from 'primereact/togglebutton';
import { useTranslation } from 'react-i18next';
// import { Form, Field } from 'react-final-form';
import Cookies from 'js-cookie';
import { Ripple } from 'primereact/ripple';
import { InputNumber } from 'primereact/inputnumber';
import { Paginator } from 'primereact/paginator';
import { ScrollTop } from 'primereact/scrolltop';

const MyUsers = (props) => {
  const [loading, setLoading] = useState(true);
  const commonCtx = useContext(CommonApi);
  const { t } = useTranslation();

  let emptyUser = {
    Active: true,
    Email: null,
    FirstName: null,
    LastName: null,
    Phone: null,
    Username: null,
    ID: null,
    buyerVisibility: false,
  };

  const [userDialog, setUserDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [impersonateeUserDialog, setImpersonateUserDialog] = useState(false);
  const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
  const [user, setUser] = useState(emptyUser);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [currentUsers, setCurrentUsers] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [emailMaskError, setEmailMaskError] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [pageInputTooltip, setPageInputTooltip] = useState('Press  Enter  key to go to this page.');
  const toast = useRef(null);
  const dt = useRef(null);

  const checkEmailMask = (email) => {
    if (/.+@.+\.[A-Za-z]+$/.test(email)) {
      setEmailMaskError(false);
    } else {
      setEmailMaskError(true);
    }
  };

  const openNew = () => {
    setUser(emptyUser);
    setSubmitted(false);
    setUserDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setUserDialog(false);
  };

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };

  const hideImpersonateUserDialog = () => {
    setImpersonateUserDialog(false);
  };

  const hideDeleteUsersDialog = () => {
    setDeleteUsersDialog(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchByKeyword();
    }
  };

  const searchByKeyword = () => {
    if (commonCtx.isSeller === false) {
      setLoading(false);
      commonCtx
        .listUsers(1, valueSearch)
        .then((users) => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      commonCtx
        .listAllUsers(1, valueSearch)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const saveUser = () => {
    if (!document.getElementById('userID').value) {
      if (emailMaskError) {
        commonCtx.showError(t('MailFormat'));
        return false;
      }
      if (
        !document.getElementById('username').value ||
        !document.getElementById('firstname').value ||
        !document.getElementById('lastname').value ||
        !document.getElementById('phone').value
      ) {
        // req fields
        commonCtx.showError(t('MandatoryFieldsMissing'));
        return false;
      }

      const params = {
        Username: document.getElementById('username').value,
        FirstName: document.getElementById('firstname').value,
        LastName: document.getElementById('lastname').value,
        Phone: document.getElementById('phone').value,
        Email: document.getElementById('username').value,
        Active: true,
        xp: {
          Country: commonCtx.me?.xp.Country,
          Visible: true,
          SendToken: false,
          buyerID: commonCtx.me?.Buyer.ID,
          CustomerCode: commonCtx.me?.xp.CustomerCode,
          Language: commonCtx.me?.xp.Language,
          buyerVisibility:
            document.getElementById('buyerVisibility').ariaPressed === 'true' ? true : false,
        },
      };
      commonCtx.createUser(params).then((user) => {
        const buyerId = commonCtx.me?.Buyer.ID;

        // for every address add the assignment
        commonCtx.listAddresses(user);

        commonCtx.setUserViewAllOrder(
          user.ID,
          commonCtx.me?.Buyer.ID,
          document.getElementById('buyerVisibility').ariaPressed
        );

        UserGroups.SaveUserAssignment(buyerId, {
          UserGroupID: buyerId + '_catalog',
          UserID: user.ID,
        });

        UserGroups.SaveUserAssignment(buyerId, {
          UserGroupID: buyerId + '-NeedsApproval',
          UserID: user.ID,
        }).then((users) => {
          commonCtx
            .listUsers(currentPage)
            .then((users) => {
              hideDialog();
              setLoading(false);
            })
            .catch(() => {
              hideDialog();
              setLoading(false);
            });
        });
      });
    } else {
      const params = {
        Username: document.getElementById('username').value,
        FirstName: document.getElementById('firstname').value,
        LastName: document.getElementById('lastname').value,
        Phone: document.getElementById('phone').value,
        Email: document.getElementById('email').value,
        xp: {
          buyerVisibility:
            document.getElementById('buyerVisibility').ariaPressed === 'true' ? true : false,
        },
      };
      commonCtx.updateUser(document.getElementById('userID').value, params).then((users) => {
        hideDialog();

        commonCtx.setUserViewAllOrder(
          user.ID,
          commonCtx.me?.Buyer.ID,
          document.getElementById('buyerVisibility').ariaPressed
        );

        commonCtx
          .listUsers(currentPage)
          .then((users) => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      });
    }
  };

  const confirmDeleteUser = (user) => {
    setUser(user);
    setDeleteUserDialog(true);
  };

  const impersonateUser = (user) => {
    setUser(user);
    setImpersonateUserDialog(true);
  };

  const deleteUser = () => {
    setDeleteUserDialog(false);
    commonCtx
      .deleteUser(user.ID)
      .then((users) => {
        setUser(emptyUser);
        toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'User Deleted',
          life: 3000,
        });
        commonCtx
          .listUsers(currentPage)
          .then((users) => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const impersonateTheUser = () => {
    setDeleteUserDialog(false);
    commonCtx
      .impersonateUser(user.ID, user.CompanyID)
      .then((users) => {
        setLoading(false);
      })
      .catch(() => {
        hideImpersonateUserDialog();
        setLoading(false);
      });
  };

  const confirmDeleteSelected = () => {
    setDeleteUsersDialog(true);
  };

  const validate = (data) => {
    let errors = {};

    if (!data.Username) {
      errors.Username = 'Name is required.';
    }

    if (!data.Email) {
      errors.Email = 'Email is required.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.Email)) {
      errors.Email = 'Invalid email address. E.g. example@email.com';
    }

    if (!data.FirstName) {
      errors.FirstName = 'Password is required.';
    }

    if (!data.LastName) {
      errors.LastName = 'You need to agree to the terms and conditions.';
    }

    return errors;
  };

  /* const deleteSelectedUsers = () => {
    let _users = commonCtx.users.filter((val) => !selectedUsers.includes(val));
    setDeleteUsersDialog(false);
    setSelectedUsers(null);
    toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Users Deleted',
      life: 3000,
    });
  };
  */

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _user = { ...user };
    _user[`${name}`] = val;

    setUser(_user);
  };

  const header = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-right">
          <InputText
            placeholder={t('KeywordSearch')}
            value={valueSearch}
            className="ml-2"
            onKeyDown={handleKeyPress}
            onChange={(e) => setValueSearch(e.target.value)}
          />
          <Button
            type="button"
            className="ml-2"
            icon="pi pi-search"
            label={t('Search')}
            onClick={() => searchByKeyword()}
          />
        </span>
        <span className="p-input-icon-left">
          <Button
            label={t('New')}
            visible={commonCtx.isSeller === false}
            icon="pi pi-plus"
            className="p-button-success mr-2   height30"
            onClick={openNew}
          />
        </span>
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <ToggleButton
        checked={rowData.Active}
        onChange={(e) => commonCtx.setActiveUser(rowData.ID, e.value, currentPage)}
        onIcon="pi pi-check"
        offIcon="pi pi-times"
        aria-label="Confirmation"
      />
    );
  };

  const editUser = (user) => {
    user.buyerVisibility = user.xp.buyerVisibility;
    setUser({ ...user });
    setUserDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          tooltip={t('EditUser')}
          visible={commonCtx.isSeller === false}
          tooltipOptions={{ position: 'top' }}
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editUser(rowData)}
        />
        <Button
          icon="pi pi-user"
          tooltip={t('ImpersonateUser')}
          tooltipOptions={{ position: 'top' }}
          className="p-button-rounded p-button-info mr-2"
          visible={commonCtx.isSeller === true}
          onClick={() => impersonateUser(rowData)}
        />
        <Button
          icon="pi pi-refresh"
          visible={commonCtx.isSeller === false}
          tooltip={t('ResetPassword')}
          tooltipOptions={{ position: 'top' }}
          className="hidden p-button-rounded p-button-info mr-2"
          onClick={() => resetPassword(rowData)}
        />
        <Button
          icon="pi pi-trash"
          visible={commonCtx.isSeller === false}
          tooltip={t('DeleteUser')}
          tooltipOptions={{ position: 'top' }}
          className="p-button-rounded p-button-error"
          onClick={() => confirmDeleteUser(rowData)}
        />
      </React.Fragment>
    );
  };

  const userDialogFooter = (
    <React.Fragment>
      <Button
        label={t('Cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label={t('Save')} icon="pi pi-check" className="p-button-text" onClick={saveUser} />
    </React.Fragment>
  );
  const impersonateUserDialogFooter = (
    <React.Fragment>
      <Button
        label={t('No')}
        icon="pi pi-times"
        className="p-button-text  bg-red-500 textWhite"
        onClick={hideImpersonateUserDialog}
      />
      <Button
        label={t('Yes')}
        icon="pi pi-check"
        className="p-button-text  bg-green-500 textWhite"
        onClick={impersonateTheUser}
      />
    </React.Fragment>
  );
  const deleteUserDialogFooter = (
    <React.Fragment>
      <Button
        label={t('No')}
        icon="pi pi-times"
        className="p-button-text  bg-red-500 textWhite"
        onClick={hideDeleteUserDialog}
      />
      <Button
        label={t('Yes')}
        icon="pi pi-check"
        className="p-button-text bg-green-500 textWhite"
        onClick={deleteUser}
      />
    </React.Fragment>
  );
  const deleteUsersDialogFooter = (
    <React.Fragment>
      <Button
        label={t('No')}
        icon="pi pi-times"
        className="p-button-text  bg-red-500 textWhite"
        onClick={hideDeleteUsersDialog}
      />
      <Button
        label={t('Yes')}
        icon="pi pi-check"
        className="p-button-text  bg-green-500 textWhite"
        onClick={deleteUser}
      />
    </React.Fragment>
  );

  useEffect(() => {
    if (!commonCtx.loading && loading) {
      if (!commonCtx.isLoggedIn) {
        location.assign('/' + Cookies.get('languageID') + '/');
      } else {
        if (commonCtx.isSeller === false) {
          setLoading(false);
          commonCtx
            .listUsers(currentPage)
            .then((users) => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          commonCtx
            .listAllUsers(currentPage)
            .then(() => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }
      }
    }
  });

  useEffect(() => {
    if (commonCtx?.users?.length > 0) {
      setCurrentUsers(commonCtx?.users?.slice(0, 20));
    }
  }, [commonCtx.users]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    updateCurrentUsers(event.page + 1);
  };

  const template1 = {
    layout: ' CurrentPageReport PrevPageLink PageLinks  NextPageLink   RowsPerPageDropdown',
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },

    PrevPageLink: (options) => {
      return (
        <>
          <button
            type="button"
            className={options.className}
            onClick={() => goToPrev(options)}
            disabled={options.disabled}
          >
            <span className="p-3">{t('Previous')}</span>
            <Ripple />
          </button>
        </>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={() => goToNext(options)}
          disabled={options.disabled}
        >
          <span className="p-3">{t('Next')}</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span className={className} style={{ userSelect: 'none' }}>
            ...
          </span>
        );
      }

      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      return (
        <>
          <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            {t('GoToPage')}
            <InputNumber
              size="2"
              className="ml-1"
              tooltip={pageInputTooltip}
              onKeyDown={(e) => onPageInputKeyDown(e, options)}
            />{' '}
            of {options.totalPages}
          </span>
        </>
      );
    },
  };

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const updateCurrentUsers = (page) => {
    const startIndex = (page - 1) * 20;
    const endIndex = startIndex + 20;
    const usersToShow = commonCtx.users.slice(startIndex, endIndex);
    setCurrentUsers(usersToShow);
  };

  const goToPrev = (e) => {
    updateCurrentUsers(currentPage - 1);
    setCurrentPage(currentPage - 1);
    setFirst(first - rows);
  };

  const goToNext = (e) => {
    updateCurrentUsers(currentPage + 1);
    setCurrentPage(currentPage + 1);
    setFirst(first + rows);
  };

  const eyeUnder = (
    <div className="eyeUnder">
      <i className="pi pi-eye"></i>
    </div>
  );

  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(event.currentTarget.value);

      if (page < 0 || page > options.totalPages) {
        setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
        return false;
      } else {
        const first = currentPage ? rows * (page - 1) : 0;
        setFirst(first);
        setPageInputTooltip('Enter number to go to this page.');
      }

      updateCurrentUsers(event.currentTarget.value);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    }
  };

  return (
    <>
      <Breadcrumbs leaf={t('MyUsers')} className="mb-0"></Breadcrumbs>
      <div className="datatable-crud-demo">
        <Toast ref={toast} />

        <div className="card">
          <DataTable
            ref={dt}
            value={currentUsers}
            // loading={loading}
            selection={selectedUsers}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey="ID"
            globalFilter={globalFilter}
            header={header}
            stripedRows
            responsiveLayout="scroll"
          >
            <Column
              selectionMode="single"
              headerStyle={{ width: '3rem' }}
              exportable={false}
            ></Column>
            <Column field="ID" hidden header="ID" sortable style={{ minWidth: '12rem' }}></Column>
            <Column
              field="Username"
              header={t('Username')}
              sortable
              style={{ minWidth: '16rem' }}
            ></Column>
            <Column
              field="CustomerCode"
              header={t('CustomerCode')}
              sortable
              hidden={!commonCtx.isSeller}
              style={{ minWidth: '16rem' }}
            ></Column>
            <Column
              field="CompanyName"
              header={t('Company')}
              sortable
              hidden={!commonCtx.isSeller}
              style={{ minWidth: '16rem' }}
            ></Column>
            <Column
              field="CompanyNumber"
              header={t('CompanyNumber')}
              sortable
              hidden={!commonCtx.isSeller}
              style={{ minWidth: '16rem' }}
            ></Column>
            <Column
              field="FirstName"
              header={t('Firstname')}
              sortable
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              field="LastName"
              header={t('Lastname')}
              sortable
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              field="Email"
              header={t('Email')}
              sortable
              hidden
              style={{ minWidth: '16rem' }}
            ></Column>
            <Column
              field="Phone"
              header={t('Phone')}
              sortable
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              field="Active"
              header={t('Active')}
              body={statusTemplate}
              exportable={false}
              hidden={commonCtx.isSeller}
              style={{ minWidth: '8rem' }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '8rem' }}
            ></Column>
          </DataTable>
          {commonCtx?.users?.length > 20 && (
            <Paginator
              template={template1}
              first={first}
              rows={rows}
              totalRecords={commonCtx?.users?.length}
              onPageChange={onPageChange}
            ></Paginator>
          )}
          <ScrollTop />
        </div>

        <Dialog
          visible={userDialog}
          style={{ width: '450px' }}
          header={!user.Username ? t('CreateNewUser') : t('EditUser')}
          modal
          className="p-fluid"
          footer={userDialogFooter}
          onHide={hideDialog}
        >
          <div className="formgrid hidden ">
            <div className="field">
              <label htmlFor="id">ID *</label>
              <InputText id="userID" defaultValue={user.ID} readOnly required autoFocus />
            </div>
          </div>
          <div className="formgrid  ">
            <div className="field">
              <label htmlFor="username">{t('UserMail')}</label>
              <InputText
                id="username"
                disabled={user.Username}
                defaultValue={user.Username}
                onChange={(e) => onInputChange(e, 'username')}
                onBlur={(e) => checkEmailMask(e.target.value)}
                required={!user.Username}
                className={classNames({ 'p-invalid': (submitted && !user.name) || emailMaskError })}
              />
              {submitted && !user.name && (
                <small className="p-error">{t('Name is required.')}</small>
              )}
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="price">{t('Firstname')}</label>
              <InputText
                id="firstname"
                defaultValue={user.FirstName}
                onChange={(e) => onInputChange(e, 'firstname')}
              />
            </div>
            <div className="field col">
              <label htmlFor="lastname">{t('Lastname')}</label>
              <InputText id="lastname" defaultValue={user.LastName} />
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="phone">{t('Phone')}</label>
              <InputText id="phone" defaultValue={user.Phone} />
            </div>
            <div className="field col">
              <label htmlFor="BuyerVisibility">{t('BuyerVisibility')}</label>
              <ToggleButton
                checked={user.buyerVisibility && user.buyerVisibility === true ? true : false}
                onChange={(e) => {
                  onInputChange(e, 'buyerVisibility');
                }}
                id="buyerVisibility"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                aria-label="Confirmation"
              />
            </div>
          </div>

          <div className="formgrid grid hidden">
            <div className="field col hidden">
              <label htmlFor="email">{t('Email')}</label>
              <InputText id="email" defaultValue={user.Email} />
            </div>
            <div className="field col">
              <label htmlFor="">{t('')}</label>
              <InputText
                id="phone"
                defaultValue={user.Phone}
                onChange={(e) => onInputChange(e, 'phone')}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          visible={deleteUserDialog}
          style={{ width: '450px' }}
          header={t('Confirm')}
          modal
          footer={deleteUserDialogFooter}
          onHide={hideDeleteUserDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {user && (
              <span>
                {t('AreYouSureYouWantToDelete')} <b>{user.Username}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={impersonateeUserDialog}
          style={{ width: '450px' }}
          header={t('Confirm')}
          modal
          footer={impersonateUserDialogFooter}
          onHide={hideImpersonateUserDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {user && (
              <span>
                {t('AreYouSureYouWantToImpersonate')} <b>{user.Username}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteUsersDialog}
          style={{ width: '450px' }}
          header={t('Confirm')}
          modal
          footer={deleteUsersDialogFooter}
          onHide={hideDeleteUsersDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
            {user && <span> {t('AreYouSureYouWantToDeleteTheSelectedUsers?')} </span>}
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default MyUsers;
