import React, { useContext, useState, useEffect, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonApi from '../CommonApi';
import Breadcrumbs from '../Breadcrumbs';
import { formatDate, formatPrice, goToCart, isDev } from '../Helper';
import { InputTextarea } from 'primereact/inputtextarea';
import { useTranslation } from 'react-i18next';
import Cart from '../Cart';
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions,
} from 'primereact/api';

const Checkout = (props) => {
  const [RequestedDeliveryDate, setRequestedDeliveryDate] = useState(new Date());

  const { t } = useTranslation();
  const [CustomerPO, setCustomerPO] = useState(null);
  const [Notes, setNotes] = useState('');

  const commonCtx = useContext(CommonApi);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = commonCtx.title + ` - ${t('Checkout')}`;
    if (!commonCtx.loading && loading && !commonCtx.loadingCart) {
      if (commonCtx.isLoggedIn) {
        commonCtx.listAddresses();
      }
      setLoading(false);
    }
  });

  if (isDev) {
    console.log(CustomerPO === '' ? true : false);
  }

  return (
    <>
      <Breadcrumbs parent="Cart" leaf="Checkout"></Breadcrumbs>
      <div className="layout-content grid">
        <div className="col-12 md:col-10">
          <div className="card card-w-title global-sales ui-fluid">
            <div className="grid">
              <div className="hidden col-12 mt-4 lg:mt-0 mb-4">
                <div className="text-900 font-medium text-xl">{t('BillingAddress')}</div>
              </div>
              <div className="hidden col-12 field mb-0">
                <label htmlFor="addresses" className="text-900 font-medium mb-3"></label>
                <Dropdown
                  value={commonCtx.activeCart?.BillingAddressID}
                  onChange={(e) => {
                    commonCtx.selectBillingAddress(e.value);
                  }}
                  className="p-inputtext w-full mb-3"
                  options={commonCtx.addresses.filter((address) => address.Billing === true)}
                  optionLabel="label"
                />
              </div>

              <div className="col-12 mt-4 lg:mt-0 mb-4">
                <div className="text-900 font-medium text-xl">{t('ShippingAddress')}</div>
              </div>
              <div className="col-12 field mb-0">
                <label htmlFor="addresses" className="text-900 font-medium mb-3"></label>
                <Dropdown
                  value={commonCtx.selectedShippingAddress?.ID}
                  onChange={(e) => {
                    commonCtx.selectShippingAddress(e.value);
                  }}
                  disabled={commonCtx.loadingCart}
                  className="p-inputtext w-full mb-3"
                  options={commonCtx.addresses.filter((address) => address.Billing === false)}
                  optionLabel="label"
                  placeholder={t('ChooseAddress')}
                />
              </div>

              <div className="hidden">
                <div className="col-12 lg:col-6 field mb-0">
                  <label htmlFor="street1" className="text-900 font-medium mb-3">
                    {t('Street1')}
                  </label>
                  <input
                    id="name2"
                    readOnly
                    type="text"
                    value={
                      commonCtx.selectedShippingAddress?.Street1
                        ? commonCtx.selectedShippingAddress?.Street1
                        : ''
                    }
                    className="p-inputtext w-full mb-3"
                  />
                </div>
                <div className="col-12 lg:col-6 field mb-0">
                  <label htmlFor="street2" className="text-900 font-medium mb-3">
                    {t('Street2')}
                  </label>
                  <input
                    id="surname2"
                    readOnly
                    value={
                      commonCtx.selectedShippingAddress?.Street2
                        ? commonCtx.selectedShippingAddress?.Street2
                        : ''
                    }
                    type="text"
                    className="p-inputtext w-full mb-3"
                  />
                </div>
                <div className="col-12 lg:col-6 field mb-0">
                  <label htmlFor="pc2" className="text-900 font-medium mb-3">
                    {t('PostalCode')}
                  </label>
                  <input
                    id="zip"
                    type="text"
                    readOnly
                    value={
                      commonCtx.selectedShippingAddress?.Zip
                        ? commonCtx.selectedShippingAddress?.Zip
                        : ''
                    }
                    className="p-inputtext w-full mb-3"
                  />
                </div>
                <div className="col-12 lg:col-6 field mb-0">
                  <label htmlFor="city2" className="text-900 font-medium mb-3">
                    {t('City')}
                  </label>
                  <input
                    id="city2"
                    value={
                      commonCtx.selectedShippingAddress?.City
                        ? commonCtx.selectedShippingAddress?.City
                        : ''
                    }
                    readOnly
                    type="text"
                    className="p-inputtext w-full mb-3"
                  />
                </div>
                <div className="col-12 lg:col-6 field mb-0">
                  <label htmlFor="pc2" className="text-900 font-medium mb-3">
                    {t('Phone')}
                  </label>
                  <input
                    id="phone"
                    type="text"
                    readOnly
                    value={
                      commonCtx.selectedShippingAddress?.Phone
                        ? commonCtx.selectedShippingAddress?.Phone
                        : ''
                    }
                    className="p-inputtext w-full mb-3"
                  />
                </div>
                <div className="col-12 lg:col-6 field mb-0">
                  <label htmlFor="city2" className="text-900 font-medium mb-3">
                    {t('Country')}
                  </label>
                  <input
                    id="country2"
                    readOnly
                    type="text"
                    value={
                      commonCtx.selectedShippingAddress?.Country
                        ? commonCtx.selectedShippingAddress?.Country
                        : ''
                    }
                    className="p-inputtext w-full mb-3"
                  />
                </div>
              </div>
              <Divider className="hidden w-full px-2 mb-3" />

              <div className="col-12 text-900 font-medium text-xl mt-4 lg:mt-0 mb-4 px-2">
                {t('AddtionalInfo')}
              </div>
              <div className="col-12 lg:col-3 field mb-0">
                <label htmlFor="pc2" className="text-900 font-medium mb-3">
                  {t('PreferredDeliveryDate')} *
                </label>
                <Calendar
                  className=" w-full mb-3"
                  value={RequestedDeliveryDate}
                  showIcon
                  minDate={new Date()}
                  dateFormat={commonCtx.dateFormat}
                  onChange={(e) => {
                    commonCtx.calculatedOrder = null;
                    setRequestedDeliveryDate(e.value);
                  }}
                ></Calendar>
              </div>
              <div className="col-12 lg:col-3 field mb-0">
                <label htmlFor="city2" className="text-900 font-medium mb-3">
                  {t('CustomerPO')} *
                </label>
                <input
                  id="customerpo"
                  required
                  type="text"
                  maxLength="15"
                  className="p-inputtext w-full mb-3"
                  value={CustomerPO}
                  onChange={(e) => {
                    commonCtx.calculatedOrder = null;
                    setCustomerPO(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 lg:col-6 field mb-0">
                <label htmlFor="city2" className="text-900 font-medium mb-3">
                  {t('Notes')}
                </label>
                <InputTextarea
                  value={Notes}
                  maxLength="256"
                  onChange={(e) => {
                    commonCtx.calculatedOrder = null;
                    setNotes(e.target.value);
                  }}
                  className="p-inputtext w-full mb-3"
                  rows={1}
                  cols={30}
                  autoResize
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-2">
          <div className="card card-w-title global-sales ui-fluid">
            <div className="text-900 font-medium text-xl mb-2">{t('Checkout Summary')}</div>
            <div className="text-800 font-medium">
              <b>{commonCtx.lineItems?.length}</b>
              {' ' + t('ProductsInCart')}{' '}
            </div>
            <div className="text-500 font-medium mt-2">
              (<b>{commonCtx?.totalProductsInCart}</b>
              {' ' + t('ItemsInTotal')})
            </div>
            <hr className="  my-3 mx-0 border-top-1 border-none surface-border" />

            <div className="flex align-items-center mb-4">
              {t('Subtotal')}
              <span className="font-bold text-2xl text-900 ml-2">
                {commonCtx?.activeCart?.Subtotal
                  ? formatPrice(commonCtx?.activeCart?.Subtotal, commonCtx.locale)
                  : '-'}
              </span>
            </div>
            <div className="flex align-items-center mb-4">
              {t('Total')}
              <span
                className={
                  commonCtx?.calculatedOrder
                    ? 'font-bold text-2xl text-900 ml-2'
                    : 'font-bold text-l text-900 ml-2'
                }
              >
                {commonCtx?.calculatedOrder
                  ? formatPrice(commonCtx?.calculatedOrder?.Subtotal, commonCtx.locale)
                  : t('PleasePressCalculate')}
              </span>
            </div>
            {false && (
              <div className=" hidden flex align-items-center mb-4">
                <span className="  font-medium text-600">
                  {commonCtx?.calculatedOrder
                    ? formatPrice(commonCtx?.calculatedOrder?.Subtotal, commonCtx.locale)
                    : 0}{' '}
                  {t('SubTotal')}
                  {commonCtx?.calculatedOrder
                    ? formatPrice(commonCtx?.calculatedOrder?.TaxCost, commonCtx.locale)
                    : 0}{' '}
                  {t('Taxes')}
                </span>
              </div>
            )}
            <hr className="hidden my-3 mx-0 border-top-1 border-none surface-border" />
            <ul className="hidden list-none p-0 m-0 flex-grow-1">
              <li className="flex align-items-center mb-3">
                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                <span>
                  {t('ShippingCost')}: &nbsp;
                  <b>
                    {commonCtx.currencySymbol}{' '}
                    {commonCtx?.calculatedOrder ? commonCtx?.calculatedOrder?.ShippingCost : 0}
                  </b>
                </span>
              </li>
              <li className="flex align-items-center mb-3">
                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                <span>
                  {t('AllItemAre')}

                  <b>{t('InStock')}</b>
                </span>
              </li>
            </ul>
            <hr className="hidden mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />

            <div className="grid">
              <div className="col-2">
                <Button
                  type="button"
                  onClick={() => goToCart()}
                  icon="pi pi-angle-left"
                  className="p-button-outlined  p-3  w-full  "
                />
              </div>
              <div className="col-10">
                <button
                  disabled={
                    !commonCtx.selectedShippingAddress ||
                    commonCtx.selectedShippingAddress?.ID === null ||
                    !CustomerPO ||
                    CustomerPO === null ||
                    CustomerPO === ''
                  }
                  aria-label="Calculate"
                  onClick={() => commonCtx.calculateOrder(CustomerPO, Notes, RequestedDeliveryDate)}
                  className="p-button p-component p-3 w-full  "
                >
                  <span className="p-button-label p-c">{t('Calculate')}</span>
                </button>
              </div>
            </div>
            <Button
              label={t('BuyNow')}
              disabled={commonCtx.calculatedOrder === null || CustomerPO === ''}
              aria-label="Buy Now"
              className="p-button-success p-3 mt-4 w-12  "
              onClick={() => commonCtx.sendOrder()}
            />
          </div>
        </div>
      </div>
      <Cart fromCheckout="true"></Cart>
    </>
  );
};

export default Checkout;
