import React, { useEffect } from 'react';
import { goToHome, isDev } from '../Helper';

const Breadcrumbs = (props) => {
  const hidden = props.hidden ? 'hidden' : '';

  useEffect(() => {
    if (isDev) {
      console.log('Breadcrumbs props', props);
    }
  }, []);

  return (
    <div className={`${hidden} col-12 surface-card shadow-2 border-round p-2 mb-2`}>
      <ul className="list-none p-3 m-0 surface-card flex align-items-center font-medium overflow-x-auto">
        <li className="pr-3">
          <a className="cursor-pointer">
            <i className="pi pi-home text-blue-500  hoverBlack" onClick={() => goToHome()}></i>
          </a>
        </li>
        {props.category && (
          <>
            <li className="px-2">
              <i className="pi pi-angle-right text-500"></i>
            </li>
            {props.categoryLink && (
              <li className="px-2 pointer">
                <span
                  className="cursor-pointer text-blue-500 white-space-nowrap  hoverBlue"
                  onClick={props.categoryLink}
                >
                  {props.category}
                </span>
              </li>
            )}

            {!props.categoryLink && (
              <li className="px-2  ">
                <span className="   white-space-nowrap ">{props.category}</span>
              </li>
            )}
          </>
        )}

        {props.subcategory && (
          <>
            <li className="px-2">
              <i className="pi pi-angle-right text-500"></i>
            </li>
            <li className="px-2 pointer">
              <span
                className="cursor-pointer text-blue-500 white-space-nowrap  hoverBlack"
                onClick={props.subCategoryLink}
              >
                {props.subcategory}
              </span>
            </li>
          </>
        )}
        <li className="px-2">
          <i className="pi pi-angle-right text-500"></i>
        </li>
        <li className="px-2">
          <span className=" white-space-nowrap">{props.leaf}</span>
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
